import { SourceComponents } from '@updater/informant-core';
import { CheckPillList as UDSCheckPillList } from '@updater/ui-uds';

export const CheckPillList: SourceComponents['Checklist'] = ({
  value,
  options,
  onChange,
  name,
  ...rest
}) => {
  return (
    <UDSCheckPillList
      value={value || []}
      name={name}
      onChange={onChange}
      options={options}
      {...rest}
    />
  );
};
