import { DragEvent, useRef } from 'react';

import { Box } from '../box';
import { Flex } from '../flex';
import { Text } from '../text';

import { Palette } from '../../constants/palette';
import { useViewportSizes } from '../../hooks/use-viewport-sizes';
import { FileInputButton } from './FileInputButton';

const FileUpArrow = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 28H6.99902C6.73381 28 6.47945 27.8946 6.29192 27.7071C6.10438 27.5196 5.99902 27.2652 5.99902 27V5C5.99902 4.73478 6.10438 4.48043 6.29192 4.29289C6.47945 4.10536 6.73381 4 6.99902 4H19L26 11V27C26 27.1313 25.9741 27.2614 25.9239 27.3827C25.8736 27.504 25.8 27.6143 25.7071 27.7071C25.6143 27.8 25.504 27.8736 25.3827 27.9239C25.2614 27.9741 25.1313 28 25 28Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 4V11H26.001"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 18.5L16 15L19.5 18.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 23V15"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

interface GetFileErrorProps {
  allowedMimeTypes: string[];
  file: File;
  maxAllowedSizeInMb: number;
}

export interface FileUploaderProps {
  allowedMimeTypes: string[];
  allowedTypesMessageForUser: string;
  maxAllowedSizeInMb?: number;
  explanatoryMessageBelow?: boolean;
  onFileErrors: (error: string[]) => void;
  onValidFiles: (files: File[]) => void;
}

function getFileExtensions(allowedMimeTypes: string[]) {
  let extensions = '';
  allowedMimeTypes.forEach((el) => {
    if (el && typeof el === 'string') {
      extensions = `${extensions} ${el.split('/').pop()?.toUpperCase()}`;
    }
  });

  return extensions;
}

const getFileError = ({
  allowedMimeTypes,
  file,
  maxAllowedSizeInMb = 5,
}: GetFileErrorProps) => {
  if (allowedMimeTypes.indexOf(file.type) === -1)
    return `${
      file.name
    } is not a supported format and cannot be uploaded. Please save your file as a ${getFileExtensions(
      allowedMimeTypes
    )} and try again.`;

  const fileSizeInMb = file.size / 1024 / 1024;
  if (fileSizeInMb > maxAllowedSizeInMb)
    return `${file.name} exceeds ${maxAllowedSizeInMb}MB and cannot be uploaded. Please reduce the file size to under ${maxAllowedSizeInMb}MB and try again.`;

  return null;
};

export const FileUploader = ({
  allowedMimeTypes,
  allowedTypesMessageForUser,
  maxAllowedSizeInMb = 5,
  explanatoryMessageBelow = false,
  onFileErrors,
  onValidFiles,
}: FileUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isMobile } = useViewportSizes();

  const dragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onFilesSelected = (files: File[] | FileList) => {
    const validFiles: File[] = [];
    const fileErrors = [];

    for (let i = 0; i < files.length; i += 1) {
      const currentFile = files[i];
      const fileError = getFileError({
        allowedMimeTypes,
        file: currentFile,
        maxAllowedSizeInMb,
      });

      if (!fileError) {
        validFiles.push(currentFile);
      } else {
        fileErrors.push(fileError);
      }
    }

    if (validFiles.length > 0) onValidFiles(validFiles);
    if (fileErrors.length > 0) onFileErrors(fileErrors);
  };

  const fileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const { files } = e.dataTransfer;
    if (files.length) {
      onFilesSelected(files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef?.current?.click();
  };

  const marginProps = explanatoryMessageBelow
    ? {
        marginTop: 's',
      }
    : {
        marginBottom: 's',
      };

  const message = (
    <Text
      data-cy="file-uploader-explanatory-message"
      variant={['xs', 'xs', 'm', 'm']}
      {...marginProps}
    >
      {allowedTypesMessageForUser} less than {maxAllowedSizeInMb}MB are
      supported.
    </Text>
  );

  const fileInputButtonProps = {
    onFilesSelected,
    isMobile,
    fileInputClicked,
    fileInputRef,
  };

  return (
    <Flex alignItems="center" flexDirection="column">
      {!explanatoryMessageBelow && message}
      <Box display="flex" width="100%">
        <Flex
          alignItems="center"
          flexDirection="row"
          margin="auto"
          width="100%"
        >
          {isMobile ? (
            <Box data-cy="mobile-file-uploader" margin="auto" width="100%">
              <FileInputButton label="Upload file" {...fileInputButtonProps} />
            </Box>
          ) : (
            <Box
              border={`2px dashed ${Palette.gray90}`}
              borderRadius="xs"
              data-cy="desktop-file-uploader"
              display="flex"
              height="232px"
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDragOver={dragOver}
              onDrop={fileDrop}
              padding="xs"
              width="100%"
            >
              <Flex alignItems="center" flexDirection="column" margin="auto">
                <Flex alignItems="center" flexDirection="row" marginBottom="l">
                  <FileUpArrow />
                  <Text marginLeft="s" variant="xl">
                    Drag & drop files here
                  </Text>
                </Flex>
                <Text marginBottom="s" variant="l">
                  or
                </Text>
                <FileInputButton {...fileInputButtonProps} />
              </Flex>
            </Box>
          )}
        </Flex>
      </Box>
      {explanatoryMessageBelow && message}
    </Flex>
  );
};
