import { useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { useSchema } from '../context/form-provider';
import { resolveConditionals } from '../lib/resolve-conditionals';
/**
 * Returns the data schema with the conditionals resolved
 * with the current form values
 * @returns The resovled schema
 */
export const useResolvedSchema = () => {
  const schema = useSchema();
  const { values } = useFormikContext();
  const prevResolvedSchema = useRef(schema);
  const resolvedSchema = useMemo(() => {
    const newResolvedSchema = resolveConditionals(schema, values);
    // This is to make sure we only return a new schema object
    // if the schemas differ, by doing a deep comparison of the objects.
    if (isEqual(prevResolvedSchema.current, newResolvedSchema)) {
      return prevResolvedSchema.current;
    }
    prevResolvedSchema.current = newResolvedSchema;
    return newResolvedSchema;
  }, [values, schema]);

  return resolvedSchema;
};
