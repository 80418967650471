import { createSourceComponent } from '../context/component-provider';
import { FieldComponentConfig } from '../types';

/**
 * Reconcile with intended definitions in ~/components once all circular
 * imports have been resolved in the informant/core
 */
export const defaultFieldComponents: FieldComponentConfig = {
  date: createSourceComponent('DateField'),
  datePicker: createSourceComponent('DatePickerField'),
  text: createSourceComponent('TextField'),
  select: createSourceComponent('SelectField'),
  checkbox: createSourceComponent('Checkbox'),
  radiolist: createSourceComponent('RadiolistField'),
  checklist: createSourceComponent('Checklist'),
  checkPillList: createSourceComponent('CheckPillList'),
  toggle: createSourceComponent('Checkbox'),
  switch: createSourceComponent('Checkbox'),
  phone: createSourceComponent('PhoneNumberField'),
  address: createSourceComponent('Address'),
  security: createSourceComponent('SecurityField'),
  ssn: createSourceComponent('SSNField'),
  file: createSourceComponent('FileUploader'),
};
