import { useFormikContext } from 'formik';
import { SourceComponents, useForm } from '@updater/informant-core';
import { Button } from '@updater/ui-uds';

export const SubmitButton: SourceComponents['SubmitButton'] = (props) => {
  const { disabled, children, ...rest } = props;
  const { ctaMode } = useForm();
  const { isSubmitting, isValid, isValidating } = useFormikContext();

  const buttonEnabled =
    ctaMode === 'enabled'
      ? false
      : disabled || isSubmitting || !isValid || isValidating;

  return (
    <Button
      data-cy="form-submit"
      data-testid="SubmitButton"
      size="l"
      isFluidWidth
      type="submit"
      disabled={buttonEnabled}
      {...rest}
    >
      {children}
    </Button>
  );
};
