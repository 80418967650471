import React, { ElementType, ChangeEvent, useMemo } from 'react';
import { FieldProps, Field as FormikField, FormikProps } from 'formik';
import { SuperSchema } from '../../types';
import {
  useSourceComponent,
  SourceComponents,
  FieldObjectProps,
} from '../../context';
import { optionsFromOneOf } from '../../lib';

import { useFieldComponents, useForm } from '../../context/form-provider';

import { FieldHeading } from '../field-heading';

type FieldNodeProps = {
  schema: SuperSchema;
  name?: string;
};

export type { FieldWrapperProps } from '../../context';

/**
 * A hook which retrieves a component used for wrapping fields,
 * either being derived from a custom field wrapper passed into the FormProvider
 * or the default field wrapper from UDS/React Native
 */
const useFieldWrapper = (props: { schema: SuperSchema }): ElementType => {
  const { wrapperComponents } = useForm();
  const FieldWrapperSourceComponent = useSourceComponent('FieldWrapper');

  const componentName = props.schema?.ui?.wrapperComponent;

  if (componentName) {
    if (wrapperComponents && wrapperComponents[componentName]) {
      return wrapperComponents[componentName];
    }
    console.warn(
      `Wrapper component ${componentName} was not found. Make sure you pass it into the wrapperComponents prop in FormProvider.`
    );
  }

  return FieldWrapperSourceComponent;
};

export const FieldObject: SourceComponents['FieldObject'] = (props) => {
  const { schema, name } = props;
  const FieldObjectSourceComponent = useSourceComponent('FieldObject');

  if (Object.keys(schema.properties ?? {}).length === 0) {
    // eslint-disable-next-line no-console
    console.warn(
      `Warning - field ${name} is typed as an object but has no properties`
    );
  }

  return <FieldObjectSourceComponent {...props} />;
};

type OnChangeArgs =
  | ChangeEvent<HTMLInputElement | HTMLSelectElement>
  | {
      target: {
        value: any;
        name?: string;
      };
    };

function onChangeNoValidate(form: FormikProps<any>) {
  return ({ target }: OnChangeArgs) => {
    if (!target?.name) {
      console.error(
        'No name provided for a field with validateOnChange: false. You must provide one. Value is: ',
        target.value
      );
    }
    form.setFieldValue(target?.name || '', target.value, false);
  };
}

/**
 * Renders a "node" in the schema into a field, or recursively
 * renders out subnodes if the current node is an "object" type
 */
export const FieldNode: React.FC<FieldNodeProps> = (props) => {
  const { schema, name } = props;
  const components = useFieldComponents();
  const { validators, customComponents } = useForm();
  const componentProps = schema.ui?.componentProps ?? {};

  const FieldWrapper = useFieldWrapper({ schema });
  const fieldWrapperProps = {
    width: schema.ui?.width,
    schema,
  };

  const customValidate = useMemo(() => {
    if (schema.ui?.validate) {
      if (validators && validators[schema.ui.validate]) {
        return validators[schema.ui.validate];
      }
      console.warn(
        `Informant: Missing a custom validation function for '${schema.ui?.validate}. Make sure you pass this into your FormProvider 'validators' prop.`
      );
    }
    return undefined;
  }, [schema.ui?.validate, validators]);

  if (schema.ui?.hide) return null;

  if (!name && schema.type !== 'object') {
    throw new Error(
      'A name is required for anything except schema type "object"'
    );
  }

  if (schema.ui?.component && name) {
    const { validateOnChange = true } = schema.ui;
    switch (schema.ui?.component) {
      case 'checklist':
      case 'checkPillList': {
        const Component = components[schema.ui?.component];
        let options = schema.ui?.options || [];
        if (!options.length) {
          try {
            options = optionsFromOneOf(schema?.items?.oneOf || []);
          } catch (e) {
            console.error(
              'No options provided to checklist via ui.component props, and items are malformed. They must have a title and a const property'
            );
            console.error(e);
            options = [];
          }
        }
        return (
          <FormikField name={name} validate={customValidate}>
            {({ field, form }: FieldProps) => (
              <FieldWrapper {...fieldWrapperProps}>
                <FieldHeading
                  heading={schema?.ui?.heading}
                  subHeading={schema?.ui?.subHeading}
                />
                <Component
                  {...field}
                  {...componentProps}
                  options={options}
                  onChange={(val) =>
                    form.setFieldValue(name, val, validateOnChange)
                  }
                />
              </FieldWrapper>
            )}
          </FormikField>
        );
      }

      case 'checkbox': {
        const CheckboxComponent = components.checkbox;
        return (
          <FormikField name={name} validate={customValidate}>
            {({ field, form }: FieldProps) => (
              <FieldWrapper {...fieldWrapperProps}>
                <FieldHeading
                  heading={schema?.ui?.heading}
                  subHeading={schema?.ui?.subHeading}
                />
                <CheckboxComponent
                  {...field}
                  {...componentProps}
                  checked={field.value === true}
                  id={`informant-checkbox-${name}`}
                  label={schema.ui?.label ?? ''}
                  onChange={
                    !validateOnChange
                      ? onChangeNoValidate(form)
                      : field.onChange
                  }
                />
              </FieldWrapper>
            )}
          </FormikField>
        );
      }

      case 'radiolist': {
        const RadioListComponent = components.radiolist;
        return (
          <FormikField name={name} validate={customValidate}>
            {({ field, form, meta }: FieldProps) => (
              <FieldWrapper {...fieldWrapperProps}>
                <FieldHeading
                  heading={schema?.ui?.heading}
                  subHeading={schema?.ui?.subHeading}
                />
                <RadioListComponent
                  {...field}
                  {...componentProps}
                  error={meta.touched ? meta.error : undefined}
                  options={schema.ui?.options || []}
                  onChange={(val) =>
                    form.setFieldValue(name, val, validateOnChange)
                  }
                />
              </FieldWrapper>
            )}
          </FormikField>
        );
      }

      case 'select': {
        const SelectComponent = components.select;
        return (
          <FormikField name={name} validate={customValidate}>
            {({ field, meta, form }: FieldProps) => (
              <FieldWrapper {...fieldWrapperProps}>
                <FieldHeading
                  heading={schema?.ui?.heading}
                  subHeading={schema?.ui?.subHeading}
                />
                <SelectComponent
                  {...field}
                  {...componentProps}
                  id={`informant-select-${name}`}
                  value={meta.value?.length ? meta.value : undefined}
                  options={schema.ui?.options || []}
                  error={meta.touched && meta.error ? meta.error : undefined}
                  label={schema.ui?.label ?? ''}
                  onChange={
                    !validateOnChange
                      ? onChangeNoValidate(form)
                      : field.onChange
                  }
                />
              </FieldWrapper>
            )}
          </FormikField>
        );
      }

      case 'text': {
        const TextComponent = components.text;
        return (
          <FormikField name={name} validate={customValidate}>
            {({ field, meta, form }: FieldProps) => (
              <FieldWrapper {...fieldWrapperProps}>
                <FieldHeading
                  heading={schema?.ui?.heading}
                  subHeading={schema?.ui?.subHeading}
                />
                <TextComponent
                  {...field}
                  {...componentProps}
                  id={`informant-text-${field.name}`}
                  error={meta.touched && meta.error ? meta.error : undefined}
                  label={schema.ui?.label || name}
                  type={schema.ui?.inputType || 'text'}
                  value={field.value || ''}
                  onChange={
                    !validateOnChange
                      ? onChangeNoValidate(form)
                      : field.onChange
                  }
                />
              </FieldWrapper>
            )}
          </FormikField>
        );
      }

      case 'phone': {
        const PhoneNumberInputComponent = components.phone;
        return (
          <FormikField name={name} validate={customValidate}>
            {({ field, meta, form }: FieldProps) => (
              <FieldWrapper {...fieldWrapperProps}>
                <FieldHeading
                  heading={schema?.ui?.heading}
                  subHeading={schema?.ui?.subHeading}
                />
                <PhoneNumberInputComponent
                  {...field}
                  {...componentProps}
                  id={`informant-phone-${field.name}`}
                  error={meta.touched && meta.error ? meta.error : undefined}
                  label={schema.ui?.label || name}
                  value={field.value || ''}
                  onChange={
                    !validateOnChange
                      ? onChangeNoValidate(form)
                      : field.onChange
                  }
                />
              </FieldWrapper>
            )}
          </FormikField>
        );
      }

      case 'security': {
        const SecurityComponent = components.security;
        return (
          <FormikField name={name} validate={customValidate}>
            {({ field, meta, form }: FieldProps) => (
              <FieldWrapper {...fieldWrapperProps}>
                <FieldHeading
                  heading={schema?.ui?.heading}
                  subHeading={schema?.ui?.subHeading}
                />
                <SecurityComponent
                  {...field}
                  {...componentProps}
                  id={`informant-security-${field.name}`}
                  error={meta.touched && meta.error ? meta.error : undefined}
                  label={schema.ui?.label || name}
                  type={schema.ui?.inputType || 'text'}
                  value={field.value || ''}
                  onChange={
                    !validateOnChange
                      ? onChangeNoValidate(form)
                      : field.onChange
                  }
                />
              </FieldWrapper>
            )}
          </FormikField>
        );
      }
      case 'ssn': {
        const SSNComponent = components.ssn;
        return (
          <FormikField name={name} validate={customValidate}>
            {({ field, meta, form }: FieldProps) => (
              <FieldWrapper {...fieldWrapperProps}>
                <FieldHeading
                  heading={schema?.ui?.heading}
                  subHeading={schema?.ui?.subHeading}
                />
                <SSNComponent
                  {...field}
                  {...componentProps}
                  id={`informant-ssn-${field.name}`}
                  error={meta.touched && meta.error ? meta.error : undefined}
                  label={schema.ui?.label || name}
                  onChange={(val) =>
                    form.setFieldValue(name, val, validateOnChange)
                  }
                />
              </FieldWrapper>
            )}
          </FormikField>
        );
      }
      case 'date': {
        const DateInputComponent = components.date;
        return (
          <FormikField name={name} validate={customValidate}>
            {({ field, meta, form }: FieldProps) => (
              <FieldWrapper {...fieldWrapperProps}>
                <FieldHeading
                  heading={schema?.ui?.heading}
                  subHeading={schema?.ui?.subHeading}
                />
                <DateInputComponent
                  {...field}
                  {...componentProps}
                  id={`informant-date-${field.name}`}
                  error={meta.touched && meta.error ? meta.error : undefined}
                  label={schema.ui?.label || name}
                  value={field.value || ''}
                  onChange={
                    !validateOnChange
                      ? onChangeNoValidate(form)
                      : field.onChange
                  }
                />
              </FieldWrapper>
            )}
          </FormikField>
        );
      }

      case 'datePicker': {
        const DatePickerComponent = components.datePicker;
        return (
          <FormikField name={name} validate={customValidate}>
            {({ field, meta, form }: FieldProps) => (
              <FieldWrapper {...fieldWrapperProps}>
                <FieldHeading
                  heading={schema?.ui?.heading}
                  subHeading={schema?.ui?.subHeading}
                />
                <DatePickerComponent
                  {...field}
                  {...componentProps}
                  id={`informant-datepicker-${field.name}`}
                  error={meta.touched && meta.error ? meta.error : undefined}
                  label={schema.ui?.label || name}
                  value={field.value || ''}
                  onChange={
                    !validateOnChange
                      ? onChangeNoValidate(form)
                      : field.onChange
                  }
                />
              </FieldWrapper>
            )}
          </FormikField>
        );
      }

      case 'address': {
        const AddressComponent = components.address;
        if (!validateOnChange) {
          console.warn(
            'Address component does not respect validateOnChange false. If you need it to please reach out to frontend platform'
          );
        }
        return <AddressComponent {...componentProps} name={name} />;
      }

      case 'file': {
        const FileUploaderComponent = components.file;
        if (
          !componentProps ||
          !componentProps?.allowedMimeTypes ||
          !componentProps?.allowedTypesMessageForUser
        ) {
          console.warn(
            'File uploader requires both allowedMimeTypes and allowedTypesMessageForUser to be set in ui.componentProps'
          );
          console.warn(
            'Some common mimetypes are application/pdf, image/gif, or application/msword'
          );
          console.warn(
            'allowedTypesMessageForUser is used to provide a message explaining which types are allowed'
          );
        }
        return (
          <FormikField name={name} validate={customValidate}>
            {({ meta, form }: FieldProps) => (
              <FieldWrapper {...fieldWrapperProps}>
                <FieldHeading
                  heading={schema?.ui?.heading}
                  subHeading={schema?.ui?.subHeading}
                />
                <FileUploaderComponent
                  error={meta.error}
                  onValidFiles={(files: File[]) => {
                    form.setFieldValue(name, files, validateOnChange);
                  }}
                  onFileErrors={(errors: string[]) => {
                    form.setFieldError(name, errors[0]);
                  }}
                  allowedTypesMessageForUser={
                    componentProps.allowedTypesMessageForUser as string
                  }
                  allowedMimeTypes={componentProps.allowedMimeTypes as string[]}
                />
              </FieldWrapper>
            )}
          </FormikField>
        );
      }

      default: {
        const CustomComponent = customComponents?.[schema?.ui?.component];

        if (CustomComponent) {
          return (
            <FormikField name={name} validate={customValidate}>
              {({ field, form, meta }: FieldProps) => (
                <FieldWrapper {...fieldWrapperProps}>
                  <FieldHeading
                    heading={schema?.ui?.heading}
                    subHeading={schema?.ui?.subHeading}
                  />
                  <CustomComponent
                    {...meta}
                    {...componentProps}
                    schema={schema}
                    name={name}
                    errors={meta.touched && meta.error ? meta.error : undefined}
                    {...componentProps}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(val: any) =>
                      form.setFieldValue(name, val, validateOnChange)
                    }
                    value={field.value}
                  />
                </FieldWrapper>
              )}
            </FormikField>
          );
        }

        throw Error(
          `Unable to render field "${name}", no case to deal with "${schema.ui?.component}" component.`
        );
      }
    }
  }

  if (schema.type === 'object') {
    return <FieldObject {...(props as FieldObjectProps)} />;
  }

  throw Error(
    `Unable to render field "${name}", schema properties were not recognized or we don't have a rule for this case yet.`
  );
};

export default FieldNode;
