import { useNavigate, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { Navigator } from '@updater/informant-core';

export const useReactRouterNavigator: () => Navigator = () => {
  const reactRouterNavigate = useNavigate();
  const location = useLocation();

  const navigator = useMemo(
    () => ({
      navigate: (route: string, { replace = false }) =>
        reactRouterNavigate(route, { replace }),
      getCurrentPath: () => location.pathname + location.search,
    }),
    [reactRouterNavigate, location]
  );

  return navigator;
};
