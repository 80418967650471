import { createContext, useContext, ReactNode } from 'react';
import {
  LayoutSchema,
  UISchema,
  SuperSchema,
  FormProviderProps,
  Validator,
  FieldComponentConfig,
} from '../../types';

export type FormContextValue<Value> = {
  /**
   * The data schema but processed for optimal usage
   * for rendering
   */
  schema: SuperSchema;
  /**
   * The original data schema passed into the
   * FormProvider schema prop
   */
  srcSchema: SuperSchema;
  uiSchema?: UISchema;
  layoutSchema?: LayoutSchema;
  fieldComponents: FieldComponentConfig;
  submitButtonText?: string;
  submitComponent?: ReactNode;
  fieldColumnWrapper?: ReactNode;
  customComponents: FormProviderProps<Value>['customComponents'];
  wrapperComponents: FormProviderProps<Value>['wrapperComponents'];
  fieldColumnWrapperComponent: FormProviderProps<Value>['fieldColumnWrapperComponent'];
  validators?: { [s: string]: Validator };
  trackEvent?(event: any): void;
  ctaMode: FormProviderProps<Value>['ctaMode'];
};

export const FormContext = createContext<FormContextValue<never>>(
  {} as FormContextValue<never>
);

export const useForm = <Values>(): FormContextValue<Values> =>
  useContext(FormContext);

export const useSchema = <Values>(): FormContextValue<Values>['schema'] =>
  useContext(FormContext).schema;

export const useFieldComponents = <
  Values
>(): FormContextValue<Values>['fieldComponents'] =>
  useContext(FormContext).fieldComponents;

export const useCustomValidators = () => {
  const context = useContext(FormContext);
  return context.validators;
};
