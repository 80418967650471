import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { getLatestScreen } from '../lib/get-latest-screen';
import { LayoutSchemaScreen } from '../types';

export const useGetLatestScreen = (
  screens: LayoutSchemaScreen[]
): [LayoutSchemaScreen | null, number] => {
  const { errors } = useFormikContext();
  return useMemo(
    () =>
      getLatestScreen({
        errors,
        screens: screens ?? [],
      }),
    [errors, screens]
  );
};
