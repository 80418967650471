import { SourceComponents, SourceComponentName } from './source-components';
import { useSourceComponent } from './use-source-components';

export function createSourceComponent<T extends SourceComponentName>(name: T) {
  const SourceComponent: SourceComponents[T] =
    // We use 'any' to defer support the equivalency between the name provided
    // and the result of giving that name to useSourceComponent
    (props: any) => {
      const NamedComponent = useSourceComponent(name);

      return <NamedComponent {...props} />;
    };

  return SourceComponent;
}
