import { SuperSchema } from '../types';

type SuperSchemaProperties = {
  [key: string]: SuperSchema;
};
export const flattenSchemaProperties = (
  schema: SuperSchema
): SuperSchemaProperties => {
  // Create a new copy of properties to read into
  const flatSchema: SuperSchemaProperties = {
    ...schema.properties,
  };

  // We're not using reduce + ... here because these schemas might be large
  if (schema.if) {
    // The spec says that `then` and `else` only count if there's an associated `if`
    if (schema.then) {
      const childFlatSchema = flattenSchemaProperties(schema.then);
      Object.entries(childFlatSchema).forEach(([k, v]) => {
        flatSchema[k] = v;
      });
    }
    if (schema.else) {
      const childFlatSchema = flattenSchemaProperties(schema.else);
      Object.entries(childFlatSchema).forEach(([k, v]) => {
        flatSchema[k] = v;
      });
    }
  }

  if (schema.allOf) {
    schema.allOf.forEach((childSchema) => {
      const childFlatSchema = flattenSchemaProperties(childSchema);
      Object.entries(childFlatSchema).forEach(([k, v]) => {
        flatSchema[k] = v;
      });
    });
  }

  // TODO support anyOf, oneOf

  return flatSchema;
};
