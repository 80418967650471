// Informant Example
// ***
// This shows how validation is implemented through the json schema

import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    email: {
      title: 'email',
      type: 'string',
      pattern:
        '^[a-zA-Z0-9.!#$%&‘*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$',
    },
  },
  required: ['email'],
};

export const MyForm = () => {
  const handleSubmit = (values: unknown) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };

  return (
    <>
      <FormProvider schema={schema} onSubmit={handleSubmit}>
        <Form />
      </FormProvider>
    </>
  );
};
