import { SourceComponents } from '@updater/informant-core';
import { RadioList, Error } from '@updater/ui-uds';

export const RadiolistField: SourceComponents['RadiolistField'] = ({
  error,
  ...props
}) => {
  const errorMessage =
    error === 'This field is required' ? 'Please select an option' : error;
  return (
    <>
      <RadioList {...props} />
      {errorMessage && <Error>{errorMessage}</Error>}
    </>
  );
};
