import { useEffect, useState, useMemo } from 'react';
import usePlacesAutocomplete, {
  HookArgs,
  getDetails,
} from 'use-places-autocomplete';
import intersection from 'lodash/intersection';

declare global {
  interface Window {
    google: any;
  }
}

export const removeGooglePlacesScript = (url: string) => {
  const script = document.querySelector(
    `script[src="${url}"]`
  ) as HTMLScriptElement;
  if (script) script.remove();
};

// dynamically add script to head
export const useGooglePlacesScript = (url: string) => {
  const [state, setState] = useState(url ? 'loading' : 'error');
  useEffect(() => {
    if (!url) {
      setState('error');
      // if somehow you ignore typescript errors
      console.warn('you must provide a url in order to load script');
      return;
    }

    if (window?.google?.maps.places) {
      setState('ready');
      return;
    }

    let script = document.querySelector(
      `script[src="${url}"]`
    ) as HTMLScriptElement;

    const handleScript = (e: Event) => {
      setState(e.type === 'load' ? 'ready' : 'error');
    };

    /**
     * script doesn't exist, create it and append to document.
     * Also, add 'load' and 'error' event listeners
     *  */
    if (!script) {
      script = document.createElement('script'); // create script tag
      script.type = 'text/javascript';
      script.src = url;
      script.async = true;
      document.getElementsByTagName('head')[0].appendChild(script);
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    }
    // if script exists add event listeners
    script.addEventListener('load', handleScript);
    script.addEventListener('error', handleScript);
    // eslint-disable-next-line consistent-return
    return () => {
      script.removeEventListener('load', handleScript);
      script.removeEventListener('error', handleScript);
    };
  }, [url]);

  return state;
};

/**
 * uses 'use-places-autocomplete` npm hook, for more info.:
 * https://github.com/wellyshen/use-places-autocomplete#api
 */
export type UseGooglePlacesAutoCompleteProps = Omit<HookArgs, 'initOnMount'> & {
  /**
   * `options`: {
   *    requestOptions:	object		The request options of Google Maps Places API except for input (e.g. bounds, radius etc.).
   googleMaps:	object	window.google.maps	In case you want to provide your own Google Maps object, pass the google.maps to it.
   callbackName:	string		You can provide a callback name to initialize usePlacesAutocomplete after Google script is loaded. It's useful when you load the script asynchronously.
   debounce:	number	200	Number of milliseconds to delay before making a request to Google Maps Places API.
   cache:	number | false	86400 (24 hours)	Number of seconds to cache the response data of Google Maps Places API.
   cacheKey:	string		Optional cache key so one can use multiple caches if needed.
   defaultValue:	string	""	Default value for the input element.
  }
  */
  options?: UseGooglePlacesAutoCompleteProps;
};
export interface UseGooglePlacesHook extends UseGooglePlacesAutoCompleteProps {
  /** Google Places API key */
  API_KEY: string;
  allowedResultTypes?: string[];
  requestedTypes?: string[] | null;
}
export function useGooglePlacesAutoComplete({
  API_KEY,
  options,
  allowedResultTypes = ['street_address', 'premise'],
  requestedTypes = ['address'],
}: UseGooglePlacesHook) {
  const API_URL = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
  const scriptState = useGooglePlacesScript(API_URL);

  const {
    init,
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    clearCache,
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      types: requestedTypes ?? undefined,
      componentRestrictions: { country: 'us' },
    },
    ...options,
  });

  useEffect(() => {
    // if google script is loaded then initialize autoComplete hook
    if (scriptState === 'ready') {
      init();
    }
    if (scriptState === 'error') {
      console.warn('there was an error loading the google script');
    }
    return () => {
      removeGooglePlacesScript(API_URL);
    };
  }, [init, scriptState, API_URL]);

  const filteredData = useMemo(
    () =>
      data.filter(
        ({ types }) => intersection(types, allowedResultTypes).length > 0
      ),
    [allowedResultTypes, data]
  );

  return {
    ready,
    value,
    suggestions: {
      status,
      data: filteredData,
    },
    setValue,
    clearSuggestions,
    clearCache,
    getDetails,
  };
}
