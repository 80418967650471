import { LayoutSchema, SuperSchema } from '@updater/ui-informant';
import React from 'react';
import * as BasicUsage from './BasicUsage';
import * as BasicValidation from './BasicValidation';
import * as DateInputMaskField from './DateInputMask';
import * as DatePickerInput from './DatePickerInput';
import * as PhoneNumberInput from './PhoneNumberInput';
import * as Components from './Components';
import * as CustomComponents from './CustomComponents';
import * as CustomValidationMessages from './CustomValidationMessages';
import * as CustomValidationFunctions from './CustomValidationFunctions';
import * as HiddenExistingField from './HiddenExistingField';
import * as LayoutSchemaExample from './LayoutSchema';
import CustomFieldWrapper from './CustomFieldWrapper';
import * as SubmitButtonExample from './CustomSubmitButton';
import * as DynamicFieldsExample from './DynamicFields';
import * as DynamicFlowExample from './DynamicFlow';
import * as AddressWidget from './AddressWidget';
import * as Radios from './Radios';
import * as Select from './Select';
import * as CheckList from './CheckList';
import * as Checkbox from './Checkbox';
import * as ABTestingLayoutSchemaExample from './ABTestingLayoutSchemas';
import * as TrackingExample from './EventTracking';
import * as PersistanceWithLocalStorage from './PersistanceWithLocalStorage';

interface ExampleConfig {
  name: string;
  title: string;
  route: string;
  schema: SuperSchema;
  layoutSchema?: LayoutSchema;
  component: React.ElementType;
}

const examples: ExampleConfig[] = [
  {
    name: 'BasicUsage',
    title: 'Basic Usage',
    route: 'basic-usage',
    schema: BasicUsage.schema,
    component: BasicUsage.MyForm,
  },
  {
    name: 'DateInputMask',
    title: 'Date Input Mask',
    route: 'date-input-mask',
    schema: DateInputMaskField.schema,
    component: DateInputMaskField.MyForm,
  },
  {
    name: 'DatePickerInput',
    title: 'Date Picker Input',
    route: 'date-picker-input',
    schema: DatePickerInput.schema,
    component: DatePickerInput.MyForm,
  },
  {
    name: 'PhoneNumberInput',
    title: 'Phone Number Input',
    route: 'phone-number-input',
    schema: PhoneNumberInput.schema,
    component: PhoneNumberInput.MyForm,
  },
  {
    name: 'AddressWidget',
    title: 'Address Widget',
    route: 'address-widget',
    schema: AddressWidget.schema,
    component: AddressWidget.MyForm,
  },
  {
    name: 'Radios',
    title: 'Radios',
    route: 'radios',
    schema: Radios.schema,
    component: Radios.MyForm,
  },
  {
    name: 'CheckList',
    title: 'Check List',
    route: 'check-list',
    schema: CheckList.schema,
    component: CheckList.MyForm,
  },
  {
    name: 'Select',
    title: 'Select',
    route: 'select',
    schema: Select.schema,
    component: Select.MyForm,
  },

  {
    name: 'Checkbox',
    title: 'Checkbox',
    route: 'checkbox',
    schema: Checkbox.schema,
    component: Checkbox.MyForm,
  },
  {
    name: 'BasicValidation',
    title: 'Basic Validation',
    route: 'basic-validation',
    schema: BasicValidation.schema,
    component: BasicValidation.MyForm,
  },
  {
    name: 'Components',
    title: 'Components',
    route: 'components',
    schema: Components.schema,
    component: Components.MyForm,
  },
  {
    name: 'CustomComponents',
    title: 'Custom Components',
    route: 'custom-components',
    schema: CustomComponents.schema,
    component: CustomComponents.MyForm,
  },
  {
    name: 'CustomFieldWrapper',
    title: 'Custom Field Wrapper',
    route: 'custom-field-wrapper',
    schema: CustomFieldWrapper.schema,
    component: CustomFieldWrapper.Form,
  },
  {
    name: 'CustomValidationMessages',
    title: 'Custom Validation Messages',
    route: 'custom-validation-messages',
    schema: CustomValidationMessages.schema,
    component: CustomValidationMessages.MyForm,
  },

  {
    name: 'CustomValidationFunctions',
    title: 'Custom Validation Functions',
    route: 'custom-validation-functions',
    schema: CustomValidationFunctions.schema,
    component: CustomValidationFunctions.MyForm,
  },

  {
    name: 'HiddenExistingField',
    title: 'Hide existing fields',
    route: 'hidden-existing-fields',
    schema: HiddenExistingField.schema,
    component: HiddenExistingField.MyForm,
  },
  {
    name: 'LayoutSchema',
    title: 'Layout Schema',
    route: 'layout-schema/screen1',
    schema: LayoutSchemaExample.schema,
    layoutSchema: LayoutSchemaExample.layoutSchema,
    component: LayoutSchemaExample.MyForm,
  },
  {
    name: 'A/BTestingLayouts',
    title: 'A/B Testing Layouts',
    route: 'multiple-layout-schemas/screen1',
    schema: ABTestingLayoutSchemaExample.schema,
    component: ABTestingLayoutSchemaExample.MyForm,
  },
  {
    name: 'SubmitButton',
    title: 'Custom submit button',
    route: 'submit-button',
    schema: SubmitButtonExample.schema,
    component: SubmitButtonExample.MyForm,
  },
  {
    name: 'DynamicFields',
    title: 'Dynamic fields',
    route: 'dynamic-fields',
    schema: DynamicFieldsExample.schema,
    component: DynamicFieldsExample.MyForm,
  },
  {
    name: 'DynamicFlow',
    title: 'Dynamic Flow',
    route: 'dynamic-flow/screen1',
    schema: DynamicFlowExample.schema,
    layoutSchema: DynamicFlowExample.layoutSchema,
    component: DynamicFlowExample.MyForm,
  },
  {
    name: 'EventTracking',
    title: 'Event Tracking',
    route: 'tracking/screen1',
    schema: TrackingExample.schema,
    component: TrackingExample.MyForm,
  },
  {
    name: 'PersistenceWitLocalStorage',
    title: 'Persisting form data with local storage',
    route: 'persistance-localstorage',
    schema: PersistanceWithLocalStorage.schema,
    component: PersistanceWithLocalStorage.MyForm,
  },
];

export default examples;
