import { useMemo } from 'react';
import { canGoBack } from '../lib/can-go-back';
import { LayoutSchemaScreen } from '../types';

/**
 * Hook that calculates if the user can go back or not on a LayoutSchema form
 * @param screenName The name of the current screen
 */
export const useCanGoBack = (
  screenName: string | undefined,
  screens: LayoutSchemaScreen[],
  allowFirstScreen?: boolean
): boolean => {
  return useMemo(
    () =>
      screenName
        ? canGoBack({
            currentScreenName: screenName,
            screens: screens ?? [],
            allowFirstScreen,
          })
        : false,
    [screenName, screens, allowFirstScreen]
  );
};
