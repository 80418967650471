import React, {
  createContext,
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Input, InputProps, Text, Box, Palette } from '@updater/ui-uds';
import styled from '@emotion/styled';
import { X, MapPin } from 'phosphor-react';
import { useCombobox } from 'downshift';
import { Suggestion } from 'use-places-autocomplete';
import { display, space, DisplayProps, SpaceProps } from 'styled-system';
import { useGooglePlacesAutoComplete } from '../../hooks/use-google-places-autocomplete';
import { TAddress, TStates } from '../../address/types';

export interface AddressTypeaheadInputProps extends InputProps {
  /**
   * `onSelectAddress` is a callback when the user selects an address
   * from the list of suggestions
   *
   * An address will be returned
   * @type TAddress
   */
  onSelectAddress?: (address: TAddress) => void;
  /**
   * Value of the input.
   *
   * When `onSelectAddress` is called this is where
   * you'd update the value to be what you want to use
   * from the returned address object.
   *
   */
  value: string;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  name: string;
  /**
   * `onReset` is a callback when the reset button ('x') is clicked
   */
  onReset: (event: any) => void;
  /**
   * `handleBlur` is a callback when the input is blurred.
   *  Recommended for showing errors
   *
   * It takes an optional param, `isForceSelectCallback`,
   * which auto-selects the first suggested address in the list onBlur
   */
  handleBlur?: (
    event: React.FocusEvent<HTMLInputElement>,
    isForceSelectCallback?: (isForceSelect: boolean) => void
  ) => void;
}

const AddressOptionsList = styled.ul<{ isOpen: boolean }>`
  ${space}
  width: 100%;
  top: 64px;
  margin: 0;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.interaction};
  max-height: 166px;
  overflow: scroll;
  background-color: #fff;
  list-style: none;
  border: 1px solid ${({ theme }) => theme.input.borderHoverColor};
  border-radius: ${({ theme }) => theme.radii.xs};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: 1px solid transparent;
  box-shadow: 0px 4px 4px rgba(117, 117, 117, 0.25);
  padding-inline-start: 0;
  display: ${({ isOpen }) => (isOpen ? 'visible' : 'none')};
`;

const AddressListItem = styled.li<DisplayProps & SpaceProps>`
  ${space}
  ${display}
  cursor: pointer;
  grid-template-rows: 1fr min-content;
  grid-template-columns: min-content 1fr;
  grid-template-areas:
    'mapPin primaryText'
    '1fr secondaryText';
`;

const CloseIcon = styled.button<DisplayProps>`
  ${display}
  height: ${({ theme }) => `${theme.space.m}px`};
  width: ${({ theme }) => `${theme.space.m}px`};
  border-radius: ${({ theme }) => theme.radii['50%']};
  background-color: ${({ theme }) => theme.colors.itemHoverBackground};
  border: none;
  padding: 0;
`;

const getNonDetailFormattedAddress = (
  placesSuggestion: Suggestion
): TAddress => {
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  const { terms, structured_formatting } = placesSuggestion ?? {};
  if (!terms || terms?.length === 0) {
    return {
      postalCode: '',
      street: '',
      city: '',
      state: '' as TStates,
    };
  }

  const numberOfTerms = terms?.length;
  const formattedAddress: TAddress = {
    postalCode: '',
    street: structured_formatting?.main_text,
    city: numberOfTerms > 4 ? terms[2].value : terms[1].value,
    state: (numberOfTerms > 4 ? terms[3].value : terms[2].value) as TStates,
  };
  return formattedAddress;
};

export function addressFromPlacesResponse({
  placesSuggestion,
  detailedResponse,
}: {
  placesSuggestion: Suggestion;
  detailedResponse?: any;
}): TAddress {
  if (typeof detailedResponse === 'undefined') {
    const formattedAddress = getNonDetailFormattedAddress(placesSuggestion);
    return formattedAddress;
  }

  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  const { address_components } = detailedResponse;

  const formattedAddress = address_components.reduce(
    (address: TAddress, component: any) => {
      if (component.types.includes('street_number')) {
        return {
          ...address,
          street: `${component.short_name ?? component.long_name} ${
            address.street
          }`.replace(/\s+/g, ' '),
        };
      }

      if (component.types.includes('route')) {
        return {
          ...address,
          street: `${address.street} ${
            component.short_name ?? component.long_name
          }`.replace(/\s+/g, ' '),
        };
      }

      if (
        component.types.includes('locality') ||
        component.types.includes('sublocality_level_1')
      ) {
        return {
          ...address,
          city: `${component.short_name ?? component.long_name}`,
        };
      }

      if (component.types.includes('administrative_area_level_1')) {
        return {
          ...address,
          state: `${component.short_name ?? component.long_name}`,
        };
      }

      if (component.types.includes('postal_code')) {
        return {
          ...address,
          postalCode: `${component.short_name ?? component.long_name}`,
        };
      }

      return address;
    },
    {
      postalCode: '',
      street: '',
      city: '',
      state: '',
    }
  );
  return formattedAddress;
}

const AddressTypeaheadContext = createContext({
  GOOGLE_PLACES_API_KEY: 'PROVIDE_A_KEY_VIA_THE_ADDRESS_TYPEAHEAD_PROVIDER',
});

export const AddressTypeaheadProvider = AddressTypeaheadContext.Provider;

export const AddressTypeaheadInput: React.FC<AddressTypeaheadInputProps> = ({
  error,
  label,
  onSelectAddress,
  value = '',
  onChange,
  name,
  onReset,
  handleBlur,
  ...rest
}) => {
  const addressValue = value === null ? '' : value;
  const addressElemRef = useRef<HTMLInputElement>(null);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [isInputInFocus, setIsInputInFocus] = useState(false);
  const inputRef = useRef<null | HTMLInputElement>(null);

  const { GOOGLE_PLACES_API_KEY: API_KEY } = useContext(
    AddressTypeaheadContext
  );
  const {
    suggestions: { data },
    setValue,
    clearSuggestions,
    getDetails,
  } = useGooglePlacesAutoComplete({
    API_KEY,
    options: {
      debounce: 300,
    },
  });

  const handleSelect = async (address: Suggestion) => {
    let detailedResponse;
    try {
      detailedResponse = await getDetails({
        placeId: address.place_id,
        fields: ['address_components'],
      });
    } catch (e) {
      console.error('FAILED TO GET DETAILES FOR ADDRESS', address, e);
    }
    const formattedAddress = addressFromPlacesResponse({
      placesSuggestion: address,
      detailedResponse,
    });
    if (onSelectAddress) onSelectAddress(formattedAddress);
  };

  const handleForceSelectCallback = (isForceSelectAddress: boolean) => {
    if (isForceSelectAddress && data.length > 0) {
      handleSelect(data[0]);
    }
  };

  const handleTypeaheadFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsInputInFocus(true);
    event.target.select();
    if (event.currentTarget.value.length > 0) setShowCloseIcon(true);
  };

  // Account for if input takes value from autofill
  useEffect(() => {
    if (addressValue.length > 0 && isInputInFocus) {
      setShowCloseIcon(true);
    }
  }, [addressValue, isInputInFocus]);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
  } = useCombobox({
    items: data,
    defaultHighlightedIndex: 0,
    onInputValueChange: ({ inputValue = '' }) => {
      setValue(inputValue);
    },
    onSelectedItemChange: ({ selectedItem = '' || ({} as Suggestion) }) => {
      if (selectedItem) {
        handleSelect(selectedItem);
        clearSuggestions();
        closeMenu();
      }
    },
  });

  const isAddressAutofilled = (() => {
    try {
      return addressElemRef.current?.matches(':-webkit-autofill');
    } catch (e) {
      return false;
    }
  })();

  useEffect(() => {
    if (isAddressAutofilled) {
      clearSuggestions();
      closeMenu();
    }
  }, [clearSuggestions, closeMenu, isAddressAutofilled]);

  return (
    <Box
      position="relative"
      zIndex={isInputInFocus ? 'interaction' : 'initial'}
      {...getComboboxProps()}
    >
      <Input
        data-cy="address-typeahead"
        isDropDownOpen={isOpen && data.length > 0}
        label={label}
        error={error}
        rightSlot={
          showCloseIcon && (
            <CloseIcon
              onClick={(e) => {
                onReset(e);
                closeMenu();
                inputRef?.current?.focus();
                setShowCloseIcon(false);
              }}
              role="button"
              aria-label="clear address"
              display="flex"
            >
              <X
                size={20}
                weight="thin"
                style={{ color: 'inherit', margin: 'auto', cursor: 'pointer' }}
              />
            </CloseIcon>
          )
        }
        {...getLabelProps()}
        {...getInputProps({
          ref: inputRef,
          refKey: 'inputRef',
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(e);
            if (e.target.value.length === 0) {
              setShowCloseIcon(false);
            }
          },
          onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
            handleBlur?.(event, handleForceSelectCallback);
            // Prevent race condition - enables "x" onClick to fire before hiding "x"
            setIsInputInFocus(false);
            if (event.relatedTarget?.ariaLabel !== 'clear address')
              setShowCloseIcon(false);
          },
          onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
            handleTypeaheadFocus(event);
          },
        })}
        value={addressValue}
        name={name}
        {...rest}
      />
      <AddressOptionsList
        pb="s"
        {...getMenuProps()}
        isOpen={isOpen && data.length > 0}
      >
        {isOpen &&
          data.map((item, index) => (
            <AddressListItem
              style={
                highlightedIndex === index ? { backgroundColor: '#f5f5f5' } : {}
              }
              pt="xs"
              pl="s"
              display="grid"
              key={item.place_id}
              {...getItemProps({
                item,
                index,
              })}
            >
              <Box
                gridArea="mapPin"
                marginRight="xs"
                position="relative"
                top="50%"
              >
                <MapPin size={16} color={Palette.blue} />
              </Box>
              <Text gridArea="primaryText" variant="s">
                {item.structured_formatting?.main_text}
              </Text>
              <Text gridArea="secondaryText" variant="xs" color="#666666">
                {item.structured_formatting?.secondary_text}
              </Text>
            </AddressListItem>
          ))}
      </AddressOptionsList>
    </Box>
  );
};
