// Informant Example
// ***
// This shows how to render a Checkbox widget.
// A Checkbox widget gets rendered by default if the type if "boolean"

import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    agreeToTerms: {
      type: 'boolean',
      ui: {
        heading: 'Please agree to our terms and conditions',
        label: 'I agree to hand over all of my personal information',
      },
    },
  },
};

export const MyForm = () => {
  const handleSubmit = (values: any) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };

  return (
    <FormProvider
      schema={schema}
      onSubmit={handleSubmit}
      initialValues={{ agreeToTerms: false }}
    >
      <Form />
    </FormProvider>
  );
};
