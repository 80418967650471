import React, { useRef, useState, useEffect } from 'react';
import { Radio, Box, Text, Card, useTheme } from '@updater/ui-uds';
import styled from '@emotion/styled';
import { Warning } from 'phosphor-react';
import { TAddress } from '../types';
import { addressToMatchString } from '../utils';

interface AddressWithNormalizaion extends TAddress {
  normalizationResponse?: string;
}

interface ConfirmAddressProps {
  normalizedAddress?: TAddress;
  userAddress: AddressWithNormalizaion;
  onChange: (a: TAddress) => void;
  setInitialUserAddress: React.Dispatch<React.SetStateAction<TAddress>>;
}

const AddressDisplay: React.FC<{ address: TAddress }> = ({ address }) => (
  <>
    <Text variant="m">
      {`${address.street}${address.unit ? `, ${address.unit}` : ''}`}
    </Text>
    <Text variant="m">{`${address.city}, ${address.state} ${address.postalCode}`}</Text>
  </>
);

const CenteredRadio = styled(Radio)`
  align-self: center;
`;

const FlexCard = styled(Card)`
  display: flex;
  cursor: pointer;
  user-select: none;
  background-color: ${({ theme }) => theme.colors.reviewCardBackground};

  & + & {
    margin-top: 16px;
  }
`;

export const ConfirmAddress: React.FC<ConfirmAddressProps> = ({
  normalizedAddress,
  userAddress,
  onChange,
  setInitialUserAddress,
}) => {
  const theme = useTheme();
  const initialUserAddress = useRef(userAddress);
  const initialNormalizedAddress = useRef(normalizedAddress);
  const [isSelected, setIsSelected] = useState('normalized-address');
  useEffect(() => {
    /**
     * set default value to normalizedAddress
     * so if user clicks 'use address' button
     * without any change of selection we submit the
     * default suggestion 'normalized-address'
     */
    if (normalizedAddress) onChange(normalizedAddress);
  }, []);

  useEffect(() => {
    /**
     *  give AddressWidget access to initialUserAddress
     *  in case a user changes selections then cancels the modal
     *  we can revert the address
     *  */
    setInitialUserAddress(initialUserAddress.current);
    return () => {
      setInitialUserAddress({} as TAddress);
    };
  }, [setInitialUserAddress, initialUserAddress]);
  return (
    <Box>
      {normalizedAddress && initialNormalizedAddress.current ? (
        <>
          <Text mb="xs" as="h1" variant="xxl">
            Verify address
          </Text>
          <Text mb="l" as="h2" variant="m">
            There’s something wrong with the address you submitted. Please
            choose which version of the address you want to use.
          </Text>
          <FlexCard as="label" borderColor="light">
            <CenteredRadio
              onChange={() => {
                setIsSelected('normalized-address');

                return initialNormalizedAddress.current
                  ? onChange(initialNormalizedAddress.current)
                  : '';
              }}
              name="confirmAddress"
              id="normalizedAddressSelection"
              value={addressToMatchString(initialNormalizedAddress.current)}
              checked={isSelected === 'normalized-address'}
            />
            <Box ml="s" cursor="pointer">
              <Text as="h3" variant="mBold">
                Suggested Address
              </Text>
              <AddressDisplay address={initialNormalizedAddress.current} />
            </Box>
          </FlexCard>
          <FlexCard as="label" borderColor="light">
            <CenteredRadio
              onChange={() => {
                setIsSelected('user-address');
                return onChange(initialUserAddress.current);
              }}
              name="confirmAddress"
              id="userEnteredAddressSelection"
              value={addressToMatchString(initialUserAddress.current)}
              checked={isSelected === 'user-address'}
            />
            <Box ml="s" cursor="pointer">
              <Text as="h3" variant="mBold">
                Original address
              </Text>
              <AddressDisplay address={initialUserAddress.current} />
            </Box>
          </FlexCard>
        </>
      ) : (
        <>
          <Warning color={theme.colors.warning} />
          <Text variant={['xxl', 'xxl', 'xxxl']}>
            Hmm... we don&#39;t recognize that address.
          </Text>
          <Text variant="s">Please make sure your address is correct.</Text>
          <Box mt="s" mb="s">
            <FlexCard as="label" borderColor="light">
              <Box ml="s">
                <Text as="h3" variant="mBold">
                  Entered address
                </Text>
                <AddressDisplay address={initialUserAddress.current} />
              </Box>
            </FlexCard>
          </Box>
        </>
      )}
    </Box>
  );
};
