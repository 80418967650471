// Informant Example
// ***
// This shows a form with the hideIfExisting UI prop being passed to a field

import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      minLength: 1,
      ui: {
        label: 'First name',
        width: 0.5,
      },
    },
    lastName: {
      type: 'string',
      ui: {
        label: 'Last name',
        width: 0.5,
      },
    },
    email: {
      title: 'email',
      type: 'string',
      pattern:
        '^[a-zA-Z0-9.!#$%&‘*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$',
      ui: {
        hideIfExisting: true,
      },
    },
  },
  required: ['email'],
};

export const MyForm = () => {
  const handleSubmit = (values: unknown) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };

  type InitialValueType = {
    firstName: string;
    lastName: string;
    email: string;
  };

  const initialValues: InitialValueType = {
    firstName: 'Updater',
    lastName: 'Informant',
    email: 'test@updater.com',
  };

  return (
    <FormProvider
      schema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <Form />
    </FormProvider>
  );
};

export default MyForm;
