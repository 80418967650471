/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import {
  Form,
  FormProvider,
  SuperSchema,
  LayoutSchema,
  NavProvider,
} from '@updater/ui-informant';
import { RadioList, H2, Text } from '@updater/ui-uds';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      minLength: 1,
      ui: {
        label: 'First name',
        width: 0.5,
      },
    },
    lastName: {
      type: 'string',
      ui: {
        label: 'Last name',
        width: 0.5,
      },
    },
    address: {
      type: 'object',
      properties: {
        lineOne: {
          type: 'string',
          minLength: 1,
          ui: {
            label: 'Address',
          },
        },
      },
      required: ['lineOne'],
    },
    email: {
      title: 'email',
      type: 'string',
      ui: {
        label: 'Email',
      },
    },
    password: {
      title: 'password',
      type: 'string',
      ui: {
        label: 'Choose a password',
      },
    },
    date: {
      title: 'date',
      type: 'string',
      ui: {
        component: 'datePicker',
        label: 'Move Date',
        componentProps: {
          maxDate: new Date('20220501'),
        },
      },
    },
    homesize: {
      title: 'homesize',
      type: 'string',
      oneOf: [
        {
          const: 'studio',
          title: 'Studio',
        },
        {
          const: 'one-bedroom',
          title: 'One Bedroom',
        },
        {
          const: 'two-bedroom',
          title: 'Two Bedroom',
        },
        {
          const: 'three-bedroom-plus',
          title: 'Three Bedroom +',
        },
      ],
      ui: {
        label: 'Home Size',
        heading: 'What’s the size of your current home?',
      },
    },
    ownership: {
      title: 'ownership',
      type: 'string',
      oneOf: [
        {
          const: 'rent',
          title: 'Rent',
        },
        {
          const: 'own',
          title: 'Own',
        },
      ],
      ui: {
        label: 'Will you rent or own your new home?',
        component: 'radiolist',
        componentProps: {
          controlSize: 'l',
        },
      },
    },
    fromAddress: {
      title: 'fromAddress',
      type: 'object',
      properties: {
        street: {
          title: 'Street',
          type: 'string',
        },
        unit: {
          type: 'string',
        },
        city: {
          type: 'string',
        },
        postalCode: {
          type: 'string',
        },
        state: {
          type: 'string',
        },
      },
      required: ['street', 'city', 'postalCode', 'state'],
    },
    toAddress: {
      title: 'toAddress',
      type: 'object',
      properties: {
        street: {
          title: 'Street',
          type: 'string',
        },
        unit: {
          type: 'string',
        },
        city: {
          type: 'string',
        },
        postalCode: {
          type: 'string',
        },
        state: {
          type: 'string',
        },
      },
      required: ['street', 'city', 'postalCode', 'state'],
    },
  },
  required: [
    'firstName',
    'lastName',
    'email',
    'password',
    'fromAddress',
    'toAddress',
    'date',
  ],
};

export const layoutSchema1: LayoutSchema = {
  screens: [
    {
      name: 'screen1',
      heading: "What's your name?",
      route: '/screen1',
      fields: ['firstName', 'lastName', 'email', 'password'],
    },
    {
      name: 'screen2',
      heading: 'Cool! Where are you moving from?',
      route: '/screen2',
      fields: ['fromAddress'],
    },
    {
      name: 'screen3',
      heading: 'Where are you moving to?',
      route: '/screen3',
      fields: ['toAddress'],
    },
    {
      name: 'screen4',
      heading: 'When do you plan to move?',
      route: '/screen4',
      fields: ['homesize', 'ownership', 'date'],
    },
  ],
  basePath: '/examples/multiple-layout-schemas',
};

export const layoutSchema2: LayoutSchema = {
  screens: [
    {
      name: 'screen1',
      heading: "What's your name?",
      route: '/screen1',
      fields: ['firstName', 'lastName'],
    },
    {
      name: 'screen2',
      heading: 'Cool! Where are you moving from?',
      route: '/screen2',
      fields: ['fromAddress'],
    },
    {
      name: 'screen3',
      heading: 'Where are you moving to?',
      route: '/screen3',
      fields: ['toAddress'],
    },
    {
      name: 'screen4',
      heading: 'When do you plan to move?',
      route: '/screen4',
      fields: ['homesize', 'ownership', 'date'],
    },
    {
      name: 'screen5',
      heading: 'When do you plan to move?',
      route: '/screen5',
      fields: ['email', 'password'],
    },
  ],
  basePath: '/examples/multiple-layout-schemas',
};

export const layoutSchema3: LayoutSchema = {
  screens: [
    {
      name: 'screen1',
      heading: "What's your name?",
      route: '/screen1',
      fields: ['firstName', 'lastName'],
    },
    {
      name: 'screen2',
      heading: 'Cool! Where are you moving from?',
      route: '/screen2',
      fields: ['fromAddress'],
    },
    {
      name: 'screen3',
      heading: 'Where are you moving to?',
      route: '/screen3',
      fields: ['toAddress'],
    },
    {
      name: 'screen4',
      heading: 'When do you plan to move?',
      route: '/screen4',
      fields: ['homesize', 'ownership', 'date', 'email', 'password'],
    },
  ],
  basePath: '/examples/multiple-layout-schemas',
};

const layoutSchemas = [layoutSchema1, layoutSchema2, layoutSchema3];

export const MyForm = () => {
  const [layoutSchema, setLayoutSchema] = useState('1');
  const schemaToUse = layoutSchemas[Number(layoutSchema)];

  const handleSubmit = (values: unknown) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };
  console.log(schemaToUse);

  return (
    <>
      <H2 variant="xlBold">Example of Swapping Layout Schemas.</H2>
      <Text mb="s" variant="s">
        Note swapping out layout schemas shouldn‘t really be done after form
        initialization. It‘s done here to power the example.
      </Text>
      <RadioList
        value={layoutSchema}
        options={[
          {
            label: 'Layout 1',
            value: '0',
          },
          {
            label: 'Layout 2',
            value: '1',
          },
          {
            label: 'Layout 3',
            value: '2',
          },
        ]}
        onChange={(e) => {
          console.log(e);
          setLayoutSchema(e);
        }}
        controlSize="m"
      />
      <hr />
      <FormProvider
        schema={schema}
        layoutSchema={schemaToUse}
        onSubmit={handleSubmit}
        initialValues={{
          firstName: 'Eraldo',
          lastName: 'Forgoli',
          email: 'eraldoforgoli@example.com',
        }}
        onInitialLoad={() => console.log('FORM LOADED')}
        key={Number(layoutSchema)}
      >
        <NavProvider
          onAfterNavigate={(e) => console.log('NAVIGATE END', e)}
          onBeforeNavigate={(e) => console.log('NAVIGATE START', e)}
          onFirstScreenBack={() => console.log('Clicked first screen back')}
        >
          <Form />
        </NavProvider>
      </FormProvider>
    </>
  );
};
