// Informant Example
// ***
// This shows how to render a select widget.
// A select widget gets rendered by default if you supply a
// "oneOf" property to your field.

import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    favouriteIcecream: {
      title: 'Favourite icecream',
      type: 'string',
      oneOf: [
        {
          const: 'chocolate',
          title: 'Chocolate',
        },
        {
          const: 'vanilla',
          title: 'Vanilla',
        },
        {
          const: 'coffee',
          title: 'Coffee',
        },
      ],
      ui: {
        label: 'Favourite icecream',
      },
    },
  },
};

export const MyForm = () => {
  const handleSubmit = (values: any) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };

  return (
    <FormProvider schema={schema} onSubmit={handleSubmit}>
      <Form />
    </FormProvider>
  );
};
