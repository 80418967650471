import { useLazyQuery, gql } from '@apollo/client';
import { TAddress, TNormalizedAddress } from '../types';

export const NORMALIZE_ADDRESS = gql`
  query normalizedAddress($input: AddressInput!) {
    normalizedAddress(input: $input) {
      normalizedAddress {
        unit
        street
        city
        state
        postalCode
        components
        analysis
        metadata
        status
        classification
      }
    }
  }
`;

type NormalizedAddressVars = {
  input: TAddress;
};

export type NormalizedAddressQueryResponse = {
  normalizedAddress: {
    normalizedAddress: TNormalizedAddress;
  };
};

/**
 * Creates a lazy query to request normalization of an address
 * @returns Apollo Lazy Query hook for the normalized address query
 */
export function useNormalizedAddressQuery() {
  return useLazyQuery<NormalizedAddressQueryResponse, NormalizedAddressVars>(
    NORMALIZE_ADDRESS
  );
}
