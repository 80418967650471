import { LayoutSchemaScreen } from '../types';

interface CanGoBackProps {
  currentScreenName: string;
  screens: LayoutSchemaScreen[];
  allowFirstScreen?: boolean;
}

/**
 * Calculates if the use can go back or not on a LayoutSchema form
 * @param currentScreenName The name of the current screen
 * @param screen The screens of a LayoutSchema
 */

export const canGoBack = ({
  currentScreenName,
  screens = [],
  allowFirstScreen = false,
}: CanGoBackProps): boolean => {
  const currentScreenIndex = screens.findIndex(
    (el) => el.name === currentScreenName
  );
  return allowFirstScreen ? currentScreenIndex >= 0 : currentScreenIndex >= 1;
};
