import * as Informant from '@updater/informant-core';
import { isBrowser } from '@updater/ui-uds';
import { ReactRouterNavigator } from './ReactRouterNavProviderContainer';

export {
  NavContextValue,
  NavContext,
  useFormNav,
} from '@updater/informant-core';

export const NavProvider: typeof Informant.NavProvider = ({
  shouldRender: propsShouldRender,
  ...props
}) => {
  const shouldRender = propsShouldRender || isBrowser;
  if (!props.navigator) {
    // if nothing is provided we fallback to the old behavior of rendering
    // within a react router context and give informant-core that router's
    // navigate function
    return <ReactRouterNavigator shouldRender={shouldRender} {...props} />;
  }

  return <Informant.NavProvider shouldRender={shouldRender} {...props} />;
};
