import { TAddress, TNormalizedAddress } from './types';

const fieldsToMatchOn = ['street', 'unit', 'city', 'state', 'postalCode'];
export function addressToMatchString(address: TAddress | TNormalizedAddress) {
  return (
    Object.keys(address || {})
      .sort()
      // Only include important keys and
      // remove all whitespace to eliminate false positives when comparing
      // addresses.
      .reduce((str, key) => {
        if (fieldsToMatchOn.includes(key) && address[key as keyof TAddress]) {
          return str + address[key as keyof TAddress]?.replace(/\s/g, '');
        }
        return str;
      }, '')
  );
}

/**
 * Checks if two Addresses are the same
 * @param address1
 * @param address2
 * @returns True if the addresses are the same, false otherwise
 */
export function compareAddresses(
  address1: TAddress | TNormalizedAddress,
  address2: TAddress | TNormalizedAddress
): boolean {
  return addressToMatchString(address1) === addressToMatchString(address2);
}
