import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Box, H1, Text } from '@updater/ui-uds';
import examples from '../example-components';
import { SchemaRenderer } from './SchemaRenderer';

interface Params {
  exampleName: string;
}

export const ExamplePage: React.FC = () => {
  const params = useParams() as unknown as Params;
  const config = useMemo(
    () =>
      examples.find(
        (e) =>
          e.route === params.exampleName ||
          e.route.startsWith(params.exampleName)
      ),
    [params]
  );

  if (!config) {
    throw new Error(
      'Component not found. Look at examples-components/index.ts.'
    );
  }

  console.log('The config');

  const Component = config.component;
  const initialSchema = config.schema;
  const githubLink = `https://github.com/Updater/frontend-core/blob/main/examples/informant-playground/src/example-components/${config.name}.tsx`;
  const [schema, setSchema] = useState(initialSchema);

  return (
    <div>
      <Flex justifyContent="space-between">
        <H1 variant="xxlBold" mb="xxxl">
          {config.title}
        </H1>
        <a href={githubLink} target="_blank" rel="noreferrer">
          View source code
        </a>
      </Flex>
      <Flex>
        <Box flex="0 0 400px">
          <Text as="h2" variant="lBold">
            Schema
          </Text>

          <SchemaRenderer onChange={setSchema} json={schema} />
          {config.layoutSchema ? (
            <Box my="m">
              <Text as="h2" variant="lBold">
                Layout Schema
              </Text>

              <SchemaRenderer json={config.layoutSchema} />
            </Box>
          ) : null}
        </Box>
        <Box
          flex="0 0 500px"
          px="l"
          borderRight="1px solid #AAA"
          borderLeft="1px solid #AAA"
        >
          <Text as="h2" variant="lBold" mb="l">
            Form
          </Text>

          <Component />
        </Box>
      </Flex>
    </div>
  );
};
