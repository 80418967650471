import React from 'react';
import { useFormikContext } from 'formik';
import { SourceComponents } from '@updater/informant-core';
import { Flex, Box, H3, H2, P } from '@updater/ui-uds';
import { useFormNav } from '../../context/nav-provider';

const Pre: React.FC = ({ children }) => {
  return (
    <pre
      style={{
        padding: '5px',
        border: '1px solid #ddd',
        background: '#eee',
        borderRadius: '5px',
        margin: '0 0 20px 0px',
        whiteSpace: 'break-spaces',
        overflow: 'hidden',
      }}
    >
      {children}
    </pre>
  );
};

const Wrapper: React.FC = ({ children }) => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '0',
        left: '0',
        width: '100%',
        background: '#FFF',
        borderTop: '1px solid #DDD',
        maxHeight: '300px',
        overflowY: 'auto',
      }}
    >
      {children}
    </div>
  );
};

export const Debugger: SourceComponents['Debugger'] = () => {
  const { values, errors } = useFormikContext();
  const { screens, currentScreen } = useFormNav();
  return (
    <Wrapper>
      <Flex>
        <Box m="m" maxWidth="400px">
          <div>
            <H2 mb="s">Values</H2>
            <Pre>{JSON.stringify(values, null, 2)}</Pre>
          </div>
          <div>
            <H2 mb="s">Errors</H2>
            <Pre>{JSON.stringify(errors, null, 2)}</Pre>
          </div>
        </Box>
        <Box m="m">
          {screens ? (
            <>
              <H2 mb="s">Screens</H2>
              <Flex flexWrap="wrap">
                {screens.map((screen, index) => {
                  const isCurrent = screen.name === currentScreen;

                  return (
                    <Box
                      border={isCurrent ? '2px solid #333' : '2px solid #DDD'}
                      p="m"
                      mr="s"
                      mb="s"
                      maxWidth="100px"
                      flex="0 0 100px"
                      overflow="hidden"
                    >
                      <H3>{index}</H3>
                      <P variant="s">{screen.name}</P>
                    </Box>
                  );
                })}
              </Flex>
            </>
          ) : null}
        </Box>
      </Flex>
    </Wrapper>
  );
};
