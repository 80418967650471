import {
  Form,
  FormProvider,
  useFormContext,
  SuperSchema,
} from '@updater/ui-informant';
import { Button } from '@updater/ui-uds';

import styled from '@emotion/styled';
import { useState } from 'react';

const dataSchema: SuperSchema = {
  type: 'object',

  properties: {
    firstName: {
      type: 'string',
      minLength: 10,
      ui: {
        label: 'First name',
        validateOnChange: false,
        width: 0.5,
        componentProps: {
          helper: 'only validates on blur',
        },
      },
    },
    lastName: {
      type: 'string',
      minLength: 5,
      ui: {
        label: 'Last name',
        width: 0.5,
      },
    },
    dateOfBirth: {
      type: 'string',
      ui: {
        component: 'date',
        label: 'Date of birth',
        width: 0.5,
      },
    },
    password: {
      type: 'string',
      ui: {
        component: 'security',
        label: 'Password',
        width: 0.5,
      },
    },
    ssn: {
      type: 'string',
      ui: {
        component: 'ssn',
        label: 'social security number',
        width: 1,
      },
    },
    email: {
      title: 'email',
      type: 'string',
      pattern:
        '^[a-zA-Z0-9.!#$%&‘*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$',
      ui: {
        hideIfExisting: true,
      },
    },
    address: {
      type: 'object',
      ui: {
        heading: 'What is your address?',
      },
      properties: {
        lineOne: {
          type: 'string',
          minLength: 1,
          ui: {
            label: 'Address',
          },
        },
      },
    },
    tvOptions: {
      type: 'string',
      oneOf: [
        {
          const: 'best',
          title: 'Best Tv ever',
        },
        {
          const: 'worst',
          title: 'Worst Tv ever',
        },
      ],
      ui: {
        label: 'Select option',
        heading: 'What do you think of Samsung TV?',
      },
    },
    bestFormLibrary: {
      type: 'string',
      title: 'favorite form library',
      oneOf: [
        {
          const: 'informant',
          title: 'Informant',
        },
        {
          const: 'jsonschemaform',
          title: 'JSON Schema Form',
        },
        {
          const: 'hookform',
          title: 'React Hook Form',
        },
      ],
      ui: {
        component: 'radiolist',
        componentProps: {
          controlSize: 'l',
        },
        heading: 'Which is you favorite form libray?',
      },
      required: ['oneOf'],
    },
    bestFootballTeam: {
      type: 'string',
      title: 'best football team',
      oneOf: [
        {
          const: 'inter',
          title: 'Inter',
        },
        {
          const: 'milan',
          title: 'Milan',
        },
        {
          const: 'barcelona',
          title: 'Barcelona',
        },
        {
          const: 'real_madrid',
          title: 'Real Madrid',
        },
      ],
      ui: {
        component: 'radiolist',
        componentProps: {
          direction: 'horizontal',
          controlSize: 'l',
        },
        heading: 'Which is you favorite football team?',
      },
    },
    favoriteIceCream: {
      type: 'array',

      items: {
        type: 'string',
        oneOf: [
          {
            const: 'chocolate',
            title: 'Chocolate',
          },
          {
            const: 'vanilla',
            title: 'Vanilla',
          },
          {
            const: 'coffee',
            title: 'Coffee',
          },
        ],
      },
      ui: {
        heading: 'What is your favorite Ice cream?',
      },
      required: ['favoriteIceCream'],
    },
    terms: {
      type: 'boolean',
      ui: {
        componentProps: {
          size: 'm',
        },
        label:
          'I agree to recieve spam 20 times a day, 8 days a week. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don`t look even slightly believable. ',
      },
    },
    files: {
      type: 'array',
      ui: {
        component: 'file',
        componentProps: {
          allowedMimeTypes: ['application/pdf', 'image/gif', 'image/jpeg'],
          allowedTypesMessageForUser: 'PDFs and images',
        },
      },
    },
  },
  required: ['firstName', 'address'],
};

// TODO: we should look into using something like this to infer these types from the schema
// https://www.npmjs.com/package/json-schema-to-typescript
// https://app.shortcut.com/updater/story/73573/investigate-how-to-infer-types-from-schema
type TemporaryValueType = {
  firstName: string;
  lastName: string;
  address: {
    lineOne: string;
  };
  tvOptions: string;
  bestFormLibrary: string;
  favoriteIceCream: Array<string>;
  CustomComponent: boolean;
  email: string;
};

// important that this is defined outside the scope of the function -- or it can use `useMemo`
const initialValues: TemporaryValueType = {
  firstName: 'Updater',
  lastName: 'Informant',
  address: {
    lineOne: '',
  },
  tvOptions: '',
  bestFormLibrary: 'informant',
  favoriteIceCream: [],
  CustomComponent: false,
  email: 'test@example.com',
};

const onSubmit = async (values: TemporaryValueType) => {
  console.log('The values', values);
  await new Promise((r) => setTimeout(r, 500));
  alert(JSON.stringify(values, null, 2));
};

const Display = () => {
  const { values } = useFormContext();
  return (
    <pre>
      {JSON.stringify(
        values,
        (key, val) =>
          key === 'files' && Array.isArray(val) ? val.map((f) => f.name) : val,
        2
      )}
    </pre>
  );
};

const Errors = () => {
  const { errors } = useFormContext();
  return <pre>{JSON.stringify(errors, null, 2)}</pre>;
};

const Frame = styled.div`
  padding: 20px;
  display: flex;
  > div {
    margin-right: 20px;
  }
  textarea {
    width: 500px;
    height: 1000px;
    padding: 10px;
    background: #eee;
    border-radius: 5px;
  }
`;

const Error = styled.div`
  color: red;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    background: #4473b1;
    color: #fff;
    border: 1px solid #2f60a0;
    display: block;
    width: 100px;
    height: 30px;
  }
`;

const FormInfo = styled.div`
  display: flex;
  > * {
    flex: 0 0 300px;
    margin: 10px;
  }
`;

const FormGenerator = () => {
  const [schemaEdit, setSchemaEdit] = useState(
    JSON.stringify(dataSchema, null, 2)
  );
  const [error, setError] = useState(false);
  const [schema, setSchema] = useState(dataSchema);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSchemaEdit(e.target.value);
  };

  const handleClick = () => {
    try {
      const parsed = JSON.parse(schemaEdit);
      setSchema(parsed);
      setError(false);
    } catch (err) {
      setError(true);
    }
  };

  return (
    <Frame>
      <div>
        <HeadingWrapper>
          <h2>Schema</h2>
          <Button onClick={handleClick}>Generate</Button>
        </HeadingWrapper>
        {error ? <Error>Your schema is badly formatted</Error> : null}
        <textarea value={schemaEdit} onChange={handleChange} />
      </div>
      <div>
        <FormProvider<TemporaryValueType>
          initialValues={initialValues}
          onSubmit={onSubmit}
          schema={schema}
          onChange={(e: TemporaryValueType) => console.log('onChange', e)}
        >
          <div>
            <h2>Form</h2>
            <Form />
          </div>
          <FormInfo>
            <div>
              <h2>Form Data</h2>
              <Display />
            </div>
            <div>
              <h2>Errors</h2>
              <Errors />
            </div>
          </FormInfo>
        </FormProvider>
      </div>
    </Frame>
  );
};

export default FormGenerator;
