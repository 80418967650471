import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { LayoutSchemaScreen } from '../types/schemas';
import { canGoNext } from '../lib';

/**
 * Hook that calculates if the user can go next or not on a LayoutSchema form
 * @param currentScreenName The name of the current screen
 */
export const useCanGoNext = (
  screenName: string | undefined,
  screens: LayoutSchemaScreen[]
): boolean => {
  const { errors } = useFormikContext();

  return useMemo(
    () =>
      screenName
        ? canGoNext({
            currentScreenName: screenName,
            screens: screens ?? [],
            errors,
          })
        : false,
    [screens, screenName, errors]
  );
};
