import { SourceComponents } from '@updater/informant-core';
import { CheckList as UDSCheckList } from '@updater/ui-uds';

export const CheckList: SourceComponents['Checklist'] = ({
  value,
  options,
  onChange,
  name,
  ...rest
}) => {
  const arr = value || [];
  /*
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const { value: fieldValue } = e.target;
    const index = arr.indexOf(fieldValue);

    if (checked) {
      if (index === -1) {
        arr.push(fieldValue);
      }
    } else if (index !== -1) {
      arr.splice(index, 1);
    }

    onChange(arr);
  };
  */
  return (
    <UDSCheckList
      value={arr}
      name={name}
      onChange={(e) => {
        onChange(e);
      }}
      options={options}
      {...rest}
    />
  );
};
