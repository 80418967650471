import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from,
  RequestHandler,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

interface MakeClientOptions {
  // A function which will return the headers to be passed with every request
  requestHeaders(): object;
  // The graphql endpoint URI for this client
  url: string;
  links?: (ApolloLink | RequestHandler)[];
}

/**
 *
 * @param options
 * @returns a new ApolloClient
 * to see a full list of available headers, see:
 * https://www.apollographql.com/docs/react/api/core/ApolloClient/
 */
export default function makeClient({
  url,
  requestHeaders,
  links = [],
}: MakeClientOptions) {
  const httpLink = new HttpLink({
    uri: url,
  });

  // caller should pass a function that would return the session data necessary for authorizing the request
  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...requestHeaders(),
      },
    }));

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    /* TODO: modify to use custom updater logging middelware */
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  return new ApolloClient({
    link: from([authMiddleware, errorLink, ...links, httpLink]),
    cache: new InMemoryCache(),
  });
}

// Example usage:
// const client = makeClient({
//   requestHeaders: () => ...gets all the headers,
//   url: "app.staging.updater.com/graphql"
// });
