import defaults from 'json-schema-defaults';
import merge from 'lodash/merge';
import { SuperSchema } from '../types';
import { flattenSchemaProperties } from './flatten-schema-properties';

export const getInitialValuesWithDefaults = (
  schema: SuperSchema,
  initialValues: {} = {} as SuperSchema
) => {
  const mainSchema = {
    type: 'object',
    properties: flattenSchemaProperties(schema),
  };

  const defaultValues = defaults(mainSchema);

  const initialValuesWithDefaults = merge(defaultValues, initialValues);
  return initialValuesWithDefaults;
};
