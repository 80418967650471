import {
  FieldNode,
  SourceComponents,
  useSourceComponent,
} from '@updater/informant-core';
import { Box } from '@updater/ui-uds';

export const FieldObject: SourceComponents['FieldObject'] = ({
  name,
  schema,
}) => {
  const FieldHeading = useSourceComponent('FieldHeading');

  return (
    <>
      <Box width="100%">
        <FieldHeading
          heading={schema?.ui?.heading}
          subHeading={schema?.ui?.subHeading}
        />
      </Box>
      {Object.entries(schema.properties || {}).map(([key, propertySchema]) => {
        const fieldName = name ? `${name}.${key}` : key;
        return (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <FieldNode schema={propertySchema} name={fieldName} key={fieldName} />
        );
      })}
    </>
  );
};
