// Informant Example
// ***
// This shows how to render a CheckList widget.
// A select widget gets rendered by default if the type if an Array
// and you pass a "oneOf" property.

import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    hobbies: {
      type: 'array',
      items: {
        type: 'string',
        oneOf: [
          {
            const: 'sport',
            title: 'Sport',
          },
          {
            const: 'music',
            title: 'Music',
          },
          {
            const: 'movies',
            title: 'Movies',
          },
          {
            const: 'coding',
            title: 'Coding',
          },
        ],
      },
      ui: {
        heading: "Select which hobbies you're into",
        componentProps: {
          direction: 'horizontal',
        },
      },
    },
  },
};

export const MyForm = () => {
  const handleSubmit = (values: any) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };

  return (
    <FormProvider schema={schema} onSubmit={handleSubmit}>
      <Form />
    </FormProvider>
  );
};
