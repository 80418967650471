import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export const ChangeHandler = <Values,>({
  onChange,
}: {
  onChange: (e: Values) => void;
}): null => {
  const { values } = useFormikContext() as { values: Values };

  useEffect(() => {
    onChange(values);
  }, [onChange, values]);

  return null;
};
