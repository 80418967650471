import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { Box } from '@updater/ui-uds';
import styled from '@emotion/styled';
import examples from '../example-components';

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: #01314a;
  cursor: pointer;
  display: block;
  &:active,
  &:visited {
    color: #01314a;
  }
  &.active {
    background: #ccc;
  }
  > div {
    cursor: pointer;
  }
`;

const Menu = styled.div`
  flex: 0 0 200px;
  background: #f2f2f2;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
`;

const Main = styled.div`
  margin-left: 200px;
  padding: 40px;
  min-height: 100vh;
`;

const LinkBox: React.FC<{ url: string }> = ({ url, children }) => {
  return (
    <StyledLink to={url}>
      <Box p="s">{children}</Box>
    </StyledLink>
  );
};

export const Examples: React.FC = () => {
  const params = useParams();
  return (
    <div>
      <Box position="relative">
        <Menu>
          {examples.map((config) => {
            return (
              <LinkBox key={config.route} url={config.route}>
                {config.title}
              </LinkBox>
            );
          })}
        </Menu>
        <Main>
          <Outlet key={params.exampleName} />
        </Main>
      </Box>
    </div>
  );
};
