import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import { Validator, Schema } from 'jsonschema';

import { SuperSchema } from '../types';

// if *if* condition is met then show *then* properties in form

/**
 * Will take a schema containing `if` and `then` json schema
 * clauses, and resolve them according to a set of passed values.
 *
 * This will never mutate the original object. It returns a new
 * object if there are `if`s in the schema to resolve, otherwise it returns
 * the original schema.
 *
 * @param unresolvedSchema A schema to resolve
 * @param formValues The current values of the form
 * @returns A new schema with the conditionals resolved if there are conditionals, otherwise the original schema
 */
export const resolveConditionals = (
  unresolvedSchema: SuperSchema,
  formValues: any
) => {
  const ifClause = unresolvedSchema?.if;
  const initialSchema = cloneDeep(unresolvedSchema);
  const warning = `There is an If statement without a Then in the schema: ${unresolvedSchema}`;

  const getValidationResult = (ifCondition: SuperSchema) => {
    const validator = new Validator();
    const validationErrors = validator.validate(
      formValues,
      ifCondition as Schema
    );
    return validationErrors.errors.length;
  };

  // handle allOf conditionals
  if (unresolvedSchema.allOf) {
    return unresolvedSchema.allOf?.reduce((newSchema, subSchema) => {
      const ifCondition = subSchema.if;
      if (ifCondition) {
        const thenSchema = subSchema.then;

        if (thenSchema) {
          const ifSchemaErrors: number = getValidationResult(ifCondition);
          if (ifSchemaErrors === 0) {
            // Merge the thenSchema into the main schema
            return merge(newSchema, thenSchema);
          }
        } else {
          console.warn(warning);
        }

        // if validation errors exist on the IF clause, we didn't fullfill the IF clause
        // return orginal schema i.e. conditional properties don't exist in main properties
        return newSchema;
      }

      return newSchema;
    }, initialSchema);
  }

  // handle single conditional
  if (ifClause) {
    const thenSchema = unresolvedSchema.then;

    if (thenSchema) {
      /** if there aren't validation errors, we fullfilled the IF clause
       * show the THEN properties by merging them into the main schema
       */
      const ifSchemaErrors: number = getValidationResult(ifClause);
      if (ifSchemaErrors === 0) {
        // Merge the thenSchema into the main schema
        return merge(initialSchema, thenSchema);
      }
      // if validation errors exist on the IF clause, we didn't fullfill the IF clause
      // return orginal schema i.e. conditional properties don't exist in main properties
      return unresolvedSchema;
    }
    console.warn(warning);
  }

  return unresolvedSchema;
};
