/* eslint-disable no-console */
import React, { ComponentProps, useEffect } from 'react';
import InputMask, { BeforeMaskedStateChangeStates } from 'react-input-mask';
import { Input } from '../input';

export type DateInputProps = ComponentProps<typeof Input>;

/**
 * @param monthNumber 1-12
 * @returns 2 or 3 on whether or not it's February
 */
const getMaxFirstNumberForDay = (monthNumber: number) =>
  monthNumber !== 2 ? 3 : 2;

/**
 * Returns month, day, year from date string
 */
const splitDateString = (
  date: string | undefined,
  shouldReturnStrings?: boolean
) => {
  if (date === undefined) {
    return { month: 0, day: 0, year: 0 };
  }

  // Keep numbers and "/". Underscores if shouldReturnStrings = true
  const filteredDate = shouldReturnStrings
    ? date?.replace(/[^0-9./_]/g, '')
    : date?.replace(/[^0-9./]/g, '');
  const splitDate = filteredDate?.split('/');

  if (shouldReturnStrings) {
    const month = splitDate[0];
    const day = splitDate[1];
    const year = splitDate[2];

    return { month, day, year };
  }

  /**
   * Convert to number (base 10).
   * Set to zero if value === '' so it can skip the "if" check.
   */
  const month = splitDate[0] !== '' ? parseInt(splitDate[0], 10) : 0;
  const day = splitDate[1] !== '' ? parseInt(splitDate[1], 10) : 0;
  const year = splitDate[2] !== '' ? parseInt(splitDate[2], 10) : 0;

  return { month, day, year };
};

/**
 * Using this instead of date-fns because it can tell if a
 * date is valid as the user types.
 */
export const isValidDateString = (date: string | undefined): boolean => {
  if (date === undefined) return false;
  const { month, day, year } = splitDateString(date);

  // false if date segments are not in range
  if (month < 0 || month > 12) {
    return false;
  }
  if (day < 0 || day > 31) {
    return false;
  }
  if (year < 0) {
    return false;
  }

  return true;
};

/**
 * Converts date format (MM/dd/yyyy) to ISOString.
 */
export const unmaskUSDateMask = (date: string): string | null => {
  if (new Date(date).toString() !== 'Invalid Date') {
    return new Date(date).toISOString();
  }
  console.error(`"${date}" is not a valid date format.`);
  return null;
};

export const DateInput: React.FC<DateInputProps> = ({
  value = '',
  disabled,
  label,
  onChange,
  ...rest
}) => {
  /**
   * Prevents "bad values" from being used in date input
   * https://github.com/sanniassin/react-input-mask#beforemaskedstatechange
   */
  const beforeMaskedStateChange = ({
    previousState,
    currentState,
    nextState,
  }: BeforeMaskedStateChangeStates) => {
    let newState = nextState;
    // Used for resetting user value
    const valueWithoutUnderscore = currentState?.value.replace(/_/g, '');

    // Prevent invalid date from being used in date input
    if (
      !isValidDateString(currentState?.value) &&
      previousState !== undefined
    ) {
      newState = previousState;
    } else if (newState.value !== undefined && newState.value !== '') {
      // Assist user by auto-filling some date fields
      let {
        month: monthString,
        day: dayString,
        // eslint-disable-next-line prefer-const
        year: yearString,
      } = splitDateString(newState.value, true);
      const { month, day } = splitDateString(newState.value);

      // Assist with auto-fill month
      if (
        month > 1 &&
        month < 10 &&
        // skip this block if 2 digit date segment
        monthString.toString().replace(/_/g, '').length !== 2
      ) {
        monthString = `0${month}`;
        if (newState.selection) {
          const cursorPosition = newState.selection?.start + 2;
          newState.selection = {
            start: cursorPosition,
            end: cursorPosition,
          };
        }
      }

      // Assist with auto-fill day
      if (
        day > getMaxFirstNumberForDay(month as number) &&
        day < 10 &&
        // skip this block if 2 digit date segment
        dayString.toString().replace(/_/g, '').length !== 2
      ) {
        dayString = `0${day}`;
        if (newState.selection) {
          const cursorPosition = newState.selection?.start + 2;
          newState.selection = {
            start: cursorPosition,
            end: cursorPosition,
          };
        }
      }

      newState.value = `${monthString}/${dayString}/${yearString}`;
    }

    // Reset input value to default () if user clears input
    if (valueWithoutUnderscore === '//') {
      newState = {
        value: '__/__/____',
        selection: { start: 0, end: 0 },
      };
    }

    return newState;
  };

  /**
   * Warn if date is not valid. Gives user the opportunity
   * to correct invalid date if it somehow accepted one before.
   */
  useEffect(() => {
    if (!isValidDateString(String(value))) {
      console.warn(
        'DateInput:',
        `Default value "${value}" is not a valid date!`
      );
    }
  }, []);

  // Props from InputMask are forwarded to child component
  return (
    // @ts-ignore
    <InputMask
      mask="99/99/9999"
      onChange={onChange}
      placeholder="MM/DD/YYYY"
      beforeMaskedStateChange={beforeMaskedStateChange}
      value={value}
      disabled={disabled}
      {...rest}
    >
      <Input id={rest?.id} label={label} className="input-with-placeholder" />
    </InputMask>
  );
};
