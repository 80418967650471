import { useFormNav, SourceComponents } from '@updater/informant-core';
// eslint-disable-next-line import/no-cycle
import { Box } from '@updater/ui-uds';
// eslint-disable-next-line import/no-cycle
import { AddressWidget } from '@updater/ui-widgets';
import { useFormikContext } from 'formik';

export const Address: SourceComponents['Address'] = (props) => {
  const { goNext } = useFormNav();
  const { submitForm } = useFormikContext();
  const handleComplete = () => {
    if (goNext) {
      goNext();
    } else {
      submitForm();
    }
  };
  return (
    <Box>
      <AddressWidget {...props} onComplete={handleComplete} />
    </Box>
  );
};
