/**
 * @file An example of how to add custom validation messages to an Informant form.
 */
import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';
import React, { useCallback } from 'react';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    email: {
      title: 'email',
      type: 'string',
      pattern: '^[A-Za-z0-9._%+-]+@updater.com$',
      ui: {
        messages: {
          pattern: 'Email address must be @updater.com',
          required: 'Email address is required!',
        },
      },
    },
  },
  required: ['email'],
};

type FormData = {
  email: string;
};

const initialValues: FormData = {
  email: '',
};

export const MyForm: React.FC = () => {
  const handleSubmit = useCallback((values: FormData) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  }, []);

  return (
    <>
      <FormProvider<FormData>
        schema={schema}
        onSubmit={handleSubmit}
        ctaMode="enabled"
        initialValues={initialValues}
      >
        <Form />
      </FormProvider>
    </>
  );
};

export default MyForm;
