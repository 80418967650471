import { useMemo } from 'react';
import { LayoutSchemaScreen } from '../types/schemas';
import { useForm } from '../context/form-provider';
import { useResolvedSchema } from './use-resolved-schema';

/**
 * Hook that returns updated layoutSchema screens such that
 * it omits the screens that contain fields that don't exist in the data schema.
 *
 * This allows us to "skip" screens since `useResolvedSchema` does the work of removing
 * condtional fields out of the schema.
 */
export const useDynamicLayoutSchema = (): LayoutSchemaScreen[] => {
  const { layoutSchema } = useForm();
  const schema = useResolvedSchema();

  const newScreens = useMemo(() => {
    // Filter out fields which don't exist on the top level of the
    // data schema. This removes the hidden fields since the data
    // schema's conditionals have already been resolved.
    const screens = layoutSchema?.screens
      .map((screen) => {
        const resolvedFieldList = screen.fields.filter((field) => {
          return schema.properties && schema.properties[field] !== undefined;
        });

        return {
          ...screen,
          fields: resolvedFieldList,
        };
      })
      .filter((screen) => screen.fields.length > 0);

    return screens;
  }, [schema, layoutSchema]);

  return newScreens ?? [];
};
