/**
 * @file An example of how to add custom validation messages to an Informant form.
 */
import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';
import React, { useCallback } from 'react';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    yourNumber: {
      title: 'Enter a number',
      type: 'number',
      ui: {
        heading: 'Please enter a number which is a multiple of three',
        label: 'Number',
        validate: 'isMultipleOfThree',
        inputType: 'number',
      },
    },
  },
};

type FormData = {
  email: string;
};

const initialValues: FormData = {
  email: '',
};

const isMultipleOfThree = (value: number) => {
  if (value % 3 !== 0) {
    return 'We require a number which is a multiple of three.';
  }
  return undefined;
};

export const MyForm: React.FC = () => {
  const handleSubmit = useCallback((values: FormData) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  }, []);

  return (
    <>
      <FormProvider<FormData>
        schema={schema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validators={{
          isMultipleOfThree,
        }}
      >
        <Form />
      </FormProvider>
    </>
  );
};

export default MyForm;
