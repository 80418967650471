import React, { useMemo } from 'react';

import { useFormNav } from '../../context';
import { useSourceComponent } from '../../context';
import { useResolvedSchema } from '../../hooks';
import { getPartialSchema } from '../../lib';
import { SuperSchemaObjectType } from '../../types';

import { FieldObject } from '../field-node';

export const ScreenFields: React.FC = () => {
  const schema = useResolvedSchema();
  const { currentScreenConfig } = useFormNav();
  const FieldSetSourceComponent = useSourceComponent('FieldSet');

  const partialSchema = useMemo(() => {
    const fields = currentScreenConfig?.fields;
    if (fields) {
      return getPartialSchema({
        fields,
        schema,
      });
    }

    return null;
  }, [currentScreenConfig?.fields, schema]);

  if (partialSchema === null) {
    return null;
  }

  return (
    <FieldSetSourceComponent>
      <FieldObject schema={partialSchema as SuperSchemaObjectType} />
    </FieldSetSourceComponent>
  );
};
