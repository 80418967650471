import React, { useMemo } from 'react';

import { useForm, useSourceComponent } from '../../context';
import { useResolvedSchema } from '../../hooks';
import { getPartialSchema } from '../../lib';
import { SuperSchemaObjectType } from '../../types';

import { BackButton } from '../back-button';
import { FieldObject } from '../field-node';
import { NextButton } from '../next-button';
import { ScreenHeading } from '../screen-heading';

interface Props {
  screenName: string;
}

export const Screen: React.FC<Props> = ({ screenName }) => {
  const schema = useResolvedSchema();
  const { layoutSchema, fieldColumnWrapperComponent } = useForm();

  const FieldColumnWrapper = fieldColumnWrapperComponent || React.Fragment;
  const FieldSetSourceComponent = useSourceComponent('FieldSet');
  const ScreenContentsSourceComponent = useSourceComponent('ScreenContents');

  const partialSchema = useMemo(() => {
    if (layoutSchema) {
      const screen = layoutSchema?.screens.find((el) => el.name === screenName);
      if (!screen) throw Error('There was an error rendering the Schema');
      const { fields } = screen;

      return getPartialSchema({
        fields,
        schema,
      });
    }
    return null;
  }, [schema, layoutSchema, screenName]);

  if (partialSchema === null) {
    return null;
  }

  return (
    <>
      <BackButton />

      <ScreenHeading />

      <ScreenContentsSourceComponent>
        <FieldColumnWrapper>
          <FieldSetSourceComponent>
            <FieldObject schema={partialSchema as SuperSchemaObjectType} />
          </FieldSetSourceComponent>
        </FieldColumnWrapper>
      </ScreenContentsSourceComponent>

      <NextButton />
    </>
  );
};
