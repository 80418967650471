// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormikContext } from 'formik';
import {
  Form,
  FormProvider,
  SuperSchema,
  LayoutSchema,
  NavProvider,
} from '@updater/ui-informant';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    wantTv: {
      type: 'boolean',
      ui: {
        heading: 'Do you want to buy a TV?',
      },
    },
    firstName: {
      type: 'string',
      minLength: 1,
      ui: {
        label: 'First name',
        width: 0.5,
      },
    },
  },
  required: ['wantTv', 'firstName'],
  if: {
    properties: {
      wantTv: { const: true },
    },
  },
  then: {
    required: ['tvOptions'],
    properties: {
      tvOptions: {
        type: 'string',
        oneOf: [
          {
            const: 'samsung',
            title: 'A Samsung TV',
          },
          {
            const: 'lg',
            title: 'A LG TV',
          },
        ],
        ui: {
          label: 'Select option',
          heading: 'Which type of TV?',
        },
      },
    },
  },
};

export const layoutSchema: LayoutSchema = {
  screens: [
    {
      name: 'screen1',
      heading: 'Do you want to buy a TV?',
      route: 'screen1',
      fields: ['wantTv'],
    },
    {
      name: 'screen2',
      heading: 'Thanks! Please select a TV option.',
      route: 'screen2',
      fields: ['tvOptions'],
    },
    {
      name: 'screen3',
      heading: 'Please enter your first name',
      route: 'screen3',
      fields: ['firstName'],
    },
  ],
  basePath: '/examples/dynamic-flow/',
};

const Errors: React.FC = () => {
  const { errors } = useFormikContext();
  return <div>{JSON.stringify(errors, null, 2)}</div>;
};

type TemporaryValueType = {
  wantTv: boolean;
  firstName: string;
};

// important that this is defined outside the scope of the function -- or it can use `useMemo`
const initialValues: TemporaryValueType = {
  firstName: '',
  wantTv: false,
};

export const MyForm = () => {
  const handleSubmit = (values: unknown) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };

  return (
    <FormProvider
      schema={schema}
      layoutSchema={layoutSchema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <NavProvider>
        <Form />
      </NavProvider>
      <Errors />
    </FormProvider>
  );
};
