import { useFormikContext } from 'formik';
import { SourceComponents } from '@updater/informant-core';

export const Form: SourceComponents['Form'] = ({ children }) => {
  const { isSubmitting, handleSubmit } = useFormikContext();

  return (
    <form
      onSubmit={handleSubmit}
      style={{ pointerEvents: isSubmitting ? 'none' : 'auto' }}
    >
      {children}
    </form>
  );
};
