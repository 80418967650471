import * as Informant from '@updater/informant-core';
import { DOMComponentProvider } from '../dom-component-provider';
export {
  FormContextValue,
  FormContext,
  useForm,
  useSchema,
  useFieldComponents,
  useCustomValidators,
} from '@updater/informant-core';

export const FormProvider: typeof Informant.FormProvider = (props) => {
  return (
    <DOMComponentProvider>
      <Informant.FormProvider {...props} />
    </DOMComponentProvider>
  );
};
