import { useMemo } from 'react';
import { SuperSchema } from '../types';
import { makeValidate, ValidationFunction } from '../lib/make-validate';

type UseValidationOptions = {
  schema: SuperSchema;
};

// the options are destructued / restructued to make the memoization work properly
// eslint-disable-next-line @typescript-eslint/comma-dangle
export const useValidation = <Values>({
  schema,
}: UseValidationOptions): ValidationFunction<Values> =>
  useMemo(() => makeValidate<Values>({ schema }), [schema]);
