import React, { ComponentProps, ReactNode } from 'react';
import { LabeledCheckbox } from '../labeled-checkbox/LabeledCheckbox';
import { CheckboxColor, CheckboxSize } from '../checkbox/Checkbox';
import { CheckPill } from '../check-pill';
import { Box } from '../box';
import { Flex } from '../flex';

type Direction = 'horizontal' | 'vertical';

type GetGridStylesOptions = {
  direction?: Direction;
};

/**
 * Multi select check list
 */
export interface CheckPillListFieldProps extends GetGridStylesOptions {
  alignItems?: ComponentProps<typeof LabeledCheckbox>['alignItems'];
  name: string;
  value: Array<string>;
  color?: CheckboxColor;
  controlSize?: CheckboxSize;
  /**
   *
   * On change should produce an array of values that were
   * selected
   */
  onChange(value: Array<string>): void;
  options: Array<{
    label: string | ReactNode;
    value: string;
    id: string;
    disabled?: boolean;
  }>;
}

export const CheckPillList: React.FC<CheckPillListFieldProps> = ({
  value,
  options,
  onChange,
  name,
  direction = 'vertical',
  controlSize,
  ...rest
}) => {
  const arr = value || [];
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: fieldValue, checked } = e.target;
    const index = arr.indexOf(fieldValue);

    if (checked) {
      if (index === -1) {
        onChange([...arr, fieldValue]);
      }
    } else if (index !== -1) {
      onChange(arr.filter((val) => val !== fieldValue));
    }
  };

  return (
    <Flex flexDirection={direction === 'horizontal' ? 'row' : 'column'}>
      {options.map(({ value: fieldValue, label, id, disabled }) => {
        const isChecked = arr.includes(fieldValue);
        return (
          <Box
            key={fieldValue}
            mr={direction === 'horizontal' ? 's' : 'none'}
            mb="s"
          >
            <CheckPill
              onChange={handleChange}
              checked={isChecked}
              name={name}
              value={fieldValue}
              label={label}
              id={id}
              disabled={disabled}
              size={controlSize}
              {...rest}
            />
          </Box>
        );
      })}
    </Flex>
  );
};
