import { useMemo } from 'react';

import { useForm } from '../context/form-provider';
import { getScreenFromRoute } from '../lib/get-screen-from-route';
import { LayoutSchemaScreen } from '../types';

export const getScreenRouteFromLocation = (
  route: string,
  basePath?: string
) => {
  if (basePath) {
    return route.replace(basePath, '');
  }
  if (route.startsWith('/')) {
    return route.slice(1);
  }
  return route;
};

export const useGetScreenFromRoute = (
  screens: LayoutSchemaScreen[],
  currentPath: string
): [LayoutSchemaScreen | null, number] => {
  const { layoutSchema } = useForm();
  const path = getScreenRouteFromLocation(currentPath, layoutSchema?.basePath);
  return useMemo(
    () =>
      getScreenFromRoute({
        screens: screens ?? [],
        path,
      }),
    [path, screens]
  );
};
