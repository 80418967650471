// Informant Example
// ***
// This shows how validation is implemented through the json schema

import {
  Form,
  FormProvider,
  SuperSchema,
  useFormContext,
} from '@updater/ui-informant';
import { Button, Spinner } from '@updater/ui-uds';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    email: {
      title: 'email',
      type: 'string',
      pattern: '^\\S+@\\S+\\.\\S+$',
      ui: {
        label: 'Email',
        inputType: 'email',
        messages: {
          required: 'Please enter your email address',
          pattern: 'Please enter a valid email address',
        },
      },
    },
  },
  required: ['email'],
};

const CustomSubmitButton = () => {
  const { isSubmitting } = useFormContext();

  return (
    <Button variant="primary" size="m" isFluidWidth>
      {isSubmitting ? (
        <Spinner color="light" />
      ) : (
        'Submit Me!! (Make sure to enter an email ;)'
      )}
    </Button>
  );
};
export const MyForm = () => {
  const handleSubmit = (values: unknown) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        console.log('Submitted after 2500ms');
        console.log(values);
        resolve(values);
      }, 2500);
    });
  };

  return (
    <>
      <FormProvider
        schema={schema}
        onSubmit={handleSubmit}
        submitComponent={<CustomSubmitButton />}
        ctaMode="enabled"
      >
        <Form />
      </FormProvider>
    </>
  );
};
