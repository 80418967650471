import { SourceComponents } from '@updater/informant-core';
import { FileUploader as UDSFileUploader, Error } from '@updater/ui-uds';

export const FileUploader: SourceComponents['FileUploader'] = ({
  error,
  ...props
}) => {
  return (
    <>
      <UDSFileUploader {...props} />
      {error && (
        <div>
          <Error>{error}</Error>
        </div>
      )}
    </>
  );
};
