import React, { useContext, useMemo } from 'react';
import { SourceComponentContext, SourceComponents } from './source-components';

export type ComponentProviderProps = {
  components: SourceComponents;
};

export const ComponentProvider: React.FC<ComponentProviderProps> = ({
  components,
  children,
}) => {
  const context = useContext(SourceComponentContext);
  const union = useObjectAssignMemo(context, components);

  return context === union ? (
    <React.Fragment children={children} />
  ) : (
    <SourceComponentContext.Provider value={union} children={children} />
  );
};

function useObjectAssignMemo<T extends Record<string, unknown>, U extends T>(
  target: T,
  source: U
): T & U {
  return useMemo(() => {
    const substitute = Object.keys(source).some((k) => source[k] !== target[k]);

    return substitute
      ? Object.assign({ ...target }, source)
      : (target as T & U);
  }, [target, source]);
}
