/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Form,
  FormProvider,
  SuperSchema,
  LayoutSchema,
  NavProvider,
  ScreenFields,
  ScreenHeading,
  NextButton,
  BackButton,
} from '@updater/ui-informant';
import { useTracking } from 'react-tracking';
import { Tracker, TrackEventInput } from '@updater/ui-tracker';

const tracker = new Tracker({
  eventTypeMap: {},
  environment: 'staging',
  channel: 'test',
  debug: true,
});

export const schema: SuperSchema = {
  type: 'object',

  properties: {
    firstName: {
      type: 'string',
      minLength: 1,
      ui: {
        label: 'First name',
        width: 0.5,
      },
    },
    lastName: {
      type: 'string',
      ui: {
        label: 'Last name',
        width: 0.5,
      },
    },
    address: {
      type: 'object',
      properties: {
        lineOne: {
          type: 'string',
          minLength: 1,
          ui: {
            label: 'Address',
          },
        },
      },
      required: ['lineOne'],
    },
    email: {
      title: 'email',
      type: 'string',
      ui: {
        label: 'Email',
      },
    },
  },
  required: ['firstName', 'lastName', 'email', 'address'],
};

export const layoutSchema: LayoutSchema = {
  screens: [
    {
      name: 'screen1',
      heading: 'Please provide your full name',
      route: '/screen1',
      fields: ['firstName', 'lastName'],
    },
    {
      name: 'screen2',
      heading: 'Thanks! Now what`s you address?',
      route: '/screen2',
      fields: ['address'],
    },
    {
      name: 'screen3',
      heading: 'Please provide your email so we can stay in touch',
      route: '/screen3',
      fields: ['email'],
      nextButtonText: 'Review',
    },
  ],
  basePath: '/examples/tracking',
};

type EventType = TrackEventInput<any>;
export const MyForm = () => {
  const handleSubmit = (values: unknown) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };
  const { Track, trackEvent } = useTracking<EventType>(
    {
      domain: 'informant-playground',
    },
    {
      dispatch: (event) => {
        tracker.trackUntyped(event);
      },
    }
  );

  return (
    <Track>
      <FormProvider
        trackEvent={trackEvent}
        schema={schema}
        layoutSchema={layoutSchema}
        onSubmit={handleSubmit}
        initialValues={{
          firstName: 'Eraldo',
          lastName: 'Forgoli',
          email: 'eraldoforgoli@example.com',
        }}
        onInitialLoad={() => console.log('FORM LOADED')}
      >
        <NavProvider
          onAfterNavigate={(e) => console.log('NAVIGATE END', e)}
          onBeforeNavigate={(e) => console.log('NAVIGATE START', e)}
          onFirstScreenBack={() => console.log('Clicked first screen back')}
        >
          <Form>
            <BackButton />
            <ScreenHeading />
            <ScreenFields />
            <NextButton />
          </Form>
        </NavProvider>
      </FormProvider>
    </Track>
  );
};
