/**
 * @file An example of how to add custom components to a form.
 */
import {
  Form as InformantForm,
  FormProvider,
  SuperSchema,
} from '@updater/ui-informant';
import React, { FC, useCallback } from 'react';
import { Flex, Text } from '@updater/ui-uds';

const schema: SuperSchema = {
  type: 'object',
  properties: {
    exampleInput: {
      title: 'Example Input',
      type: 'number',
      ui: {
        label: 'Example Field With Custom Field Wrapper',
        wrapperComponent: 'FieldWrapperExampleWithBackgroundColor',
      },
    },
  },
};

type FormData = {
  exampleInput?: number;
};

const initialValues: FormData = {
  exampleInput: undefined,
};

const FieldWrapperExampleWithBackgroundColor: FC = (props) => {
  const { children } = props;

  return (
    <Flex
      border="1px solid"
      borderColor="primary"
      padding="m"
      width="100%"
      marginBottom="s"
      flexDirection="column"
    >
      <Flex marginBottom="s">
        <Text>
          The border around this field, as well as this text, are being added
          through a custom field wrapper.
        </Text>
      </Flex>

      {children}
    </Flex>
  );
};

const wrapperComponents = {
  FieldWrapperExampleWithBackgroundColor,
};

const Form: React.FC = () => {
  const handleSubmit = useCallback((values: FormData) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  }, []);

  return (
    <>
      <FormProvider<FormData>
        schema={schema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        wrapperComponents={wrapperComponents}
      >
        <InformantForm />
      </FormProvider>
    </>
  );
};

export default {
  Form,
  schema,
};
