/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BackButton,
  FormProvider,
  LayoutSchema,
  NavProvider,
  NextButton,
  // ScreenFields,
  ScreenHeading,
  SuperSchema,
  Form,
  NavEventProps,
  Debugger,
  ScreenFields,
  AddressSubSchema,
  CustomComponentProps,
} from '@updater/ui-informant';

import { LocalstoragePersistence, makeClient } from '@updater/ui-utilities';

import { useEffect, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AddressTypeaheadProvider } from '@updater/ui-widgets';
import { ApolloProvider } from '@apollo/client';
import styled from '@emotion/styled';

const client = makeClient({
  requestHeaders: () => ({
    name: 'informant-playground',
    app: 'mover',
  }),
  url: 'https://api.staging.updater.com/graphql',
});

const srcUrl =
  'https://github.com/Updater/frontend-core/blob/main/examples/informant-playground/src/components/ExampleFlow.tsx';

const SrcLink = styled.a`
  float: right;
`;

const PageWrapper = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 60px;
`;

const FormWrapper = styled.div`
  padding: 80px 0px;
`;

export const schema: SuperSchema = {
  $id: 'example-flow',
  type: 'object',
  properties: {
    wantTv: {
      type: 'boolean',
      ui: {
        label: 'Yes, I want to buy a TV',
      },
    },
    firstName: {
      type: 'string',
      minLength: 1,
      ui: {
        label: 'First name',
        width: 0.5,
      },
    },
    lastName: {
      type: 'string',
      minLength: 1,
      ui: {
        label: 'Last name',
        width: 0.5,
      },
    },
    serviceType: {
      type: 'string',
      oneOf: [
        {
          const: 'tv',
          title: 'TV',
        },
        {
          const: 'internet',
          title: 'Internet',
        },
        {
          const: 'tvinternet',
          title: 'TV Internet',
        },
      ],
      ui: {
        label: 'What type of service would you like',
        heading: 'What type of service?',
        width: 0.5,
        component: 'radiolist',
      },
    },
    wantIcecream: {
      type: 'string',
      oneOf: [
        {
          const: 'yes',
          title: 'Yes',
        },
        {
          const: 'no',
          title: 'No',
        },
      ],
      ui: {
        label: 'Do you want icecream?',
        width: 0.5,
      },
    },
    internetType: {
      type: 'string',
      oneOf: [
        {
          const: 'verizon',
          title: 'Verizon',
        },
        {
          const: 'spectrum',
          title: 'Spectrum',
        },
      ],
      ui: {
        label: 'internet service',
        heading: 'Which internet service do you want?',
      },
    },
    email: {
      title: 'email',
      type: 'string',
      ui: {
        label: 'Email',
      },
    },
    address: {
      ...AddressSubSchema,
      ...{
        ui: {
          component: 'address',
          componentProps: {
            allowMilitary: false,
            allowCommercial: false,
            commercialErrorMsg:
              "I don't think you should do that in a form flow",
          },
        },
      },
    },
  },
  required: ['firstName', 'lastName', 'email', 'address'],

  allOf: [
    {
      if: {
        properties: {
          serviceType: {
            const: 'internet',
          },
        },
      },
      then: {
        properties: {
          internetSpeed: {
            type: 'string',
            ui: {
              label: 'Choose internet speed',
            },
          },
        },
        required: ['wantIcecream'],
      },
    },
    {
      if: {
        properties: {
          serviceType: {
            const: 'tvinternet',
          },
        },
      },
      then: {
        properties: {
          channelsAndSpeed: {
            type: 'string',
            ui: {
              label: 'Choose internet speed and channels',
            },
          },
        },
        required: ['wantIcecream'],
      },
    },
    {
      if: {
        properties: {
          serviceType: {
            const: 'tv',
          },
        },
      },
      then: {
        properties: {
          channels: {
            type: 'string',
            ui: {
              label: 'Choose channels',
            },
          },
        },
        required: ['wantIcecream'],
      },
    },
    {
      if: {
        properties: {
          wantIcecream: {
            const: 'yes',
          },
        },
        required: ['wantIcecream'],
      },
      then: {
        required: ['icecreamType'],
        properties: {
          icecreamType: {
            type: 'string',
            oneOf: [
              {
                const: 'vanilla',
                title: 'Vanilla',
              },
              {
                const: 'chocolate',
                title: 'Chocolate',
              },
            ],
            ui: {
              label: 'Please select which icecrem you would like',
            },
          },
        },
      },
    },
    {
      if: {
        properties: {
          wantTv: { const: true },
        },
        required: ['wantTv'],
      },
      then: {
        required: ['tvOptions'],
        properties: {
          tvOptions: {
            type: 'string',
            oneOf: [
              {
                const: 'samsung',
                title: 'A Samsung TV',
              },
              {
                const: 'lg',
                title: 'A LG TV',
              },
            ],
            ui: {
              label: 'Select option',
              heading: 'Which type of TV?',
              component: 'radiolist',
            },
          },
        },
      },
    },
    {
      if: {
        properties: {
          internetType: { const: 'verizon' },
        },
      },
      then: {
        required: ['speedOptions'],
        properties: {
          speedOptions: {
            type: 'string',
            oneOf: [
              {
                const: '200',
                title: '200 mbs',
              },
              {
                const: '100',
                title: '100 mbs',
              },
            ],
            ui: {
              label: 'Select option',
              heading: 'Choose internet speed',
            },
          },
        },
      },
    },
    {
      if: {
        properties: {
          internetType: { const: 'spectrum' },
        },
        /**
         * if you didn't add a default value to `internetType`, adding
         * required on all “if” schemas for `internetType` besides one will
         * make the condition without the "required" the default value for `speedOptions`
         * i.e. in this schema the default is verizon's speedOptions
         *  */
        required: ['internetType'],
      },
      then: {
        required: ['speedOptions'],
        properties: {
          speedOptions: {
            type: 'string',
            oneOf: [
              {
                const: '600',
                title: '600 mbs',
              },
              {
                const: '400',
                title: '400 mbs',
              },
            ],
            ui: {
              label: 'Select option',
              heading: 'Choose internet speed',
            },
          },
        },
      },
    },
  ],
};

export const layoutSchema: LayoutSchema = {
  screens: [
    {
      name: 'serviceType',
      heading: 'Please choose your service',
      route: 'service',
      fields: ['serviceType'],
    },
    {
      name: 'screen1',
      heading: 'Please provide your full name',
      route: 'screen1',
      fields: ['firstName', 'lastName'],
    },
    {
      name: 'address',
      heading: 'Please provide your address',
      route: 'address',
      fields: ['address'],
      hideNextButton: true,
    },
    {
      name: 'internetSpeed',
      route: 'internetSpeed',
      fields: ['internetSpeed', 'channelsAndSpeed', 'channels'],
    },
    {
      name: 'channelsAndSpeed',
      route: 'channelsAndSpeed',
      fields: ['channelsAndSpeed'],
    },
    {
      name: 'channels',
      route: 'channels',
      fields: ['channels'],
    },
    {
      name: 'wantTvScreen',
      heading: 'Do you want a TV?',
      route: 'want-tv',
      fields: ['wantTv'],
    },
    {
      name: 'screen2',
      heading: 'So you like to watch TV, huh?',
      route: 'screen2',
      fields: ['tvOptions'],
    },
    {
      name: 'screen3',
      heading: 'Would you like some icecream?',
      route: 'screen3',
      fields: ['wantIcecream', 'internetType'],
    },
    {
      name: 'screen4',
      heading: 'Choose your favourite flavor',
      route: 'screen4',
      fields: ['icecreamType', 'speedOptions'],
    },
    {
      name: 'screen6',
      heading: 'Please provide your email so we can stay in touch',
      route: 'screen6',
      fields: ['email'],
      nextButtonText: 'Review',
    },
  ],
  basePath: '/example-flow/',
};

const sleep = () => new Promise((res) => setTimeout(res, 1000));

const CustomInput: React.FC<CustomComponentProps> = (props) => {
  const { onChange, value, error } = props;

  useEffect(() => {
    console.log('-- custom input mounted');
  }, []);

  const onChangeInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.value);
  };

  return (
    <div>
      <h4>Special Input</h4>
      {error && <p>{error}</p>}
      <input onChange={onChangeInput} value={value} />
    </div>
  );
};

export const ExampleFlow = () => {
  const persistence = useMemo(() => {
    return LocalstoragePersistence('my-user-id');
  }, []);

  const handleSubmit = (values: unknown) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };

  const handleBeforeNavigate = async (navigationEventProps: NavEventProps) => {
    console.log('on before navigate');
    console.log(navigationEventProps);
    await sleep();
    return true;
  };

  const handleAfterNavigate = (navigationEventProps: NavEventProps) => {
    console.log('On after nvagiate');
    console.log(navigationEventProps);
  };

  return (
    <AddressTypeaheadProvider
      value={{
        GOOGLE_PLACES_API_KEY: process.env
          .REACT_APP_GOOGLE_PLACES_API_KEY as string,
      }}
    >
      <ApolloProvider client={client}>
        <PageWrapper>
          <SrcLink href={srcUrl} target="_blank" rel="noreferrer">
            View source code
          </SrcLink>
          <div style={{ maxWidth: '500px' }}>
            <FormWrapper>
              <FormProvider
                schema={schema}
                layoutSchema={layoutSchema}
                onSubmit={handleSubmit}
                onChange={(e) => console.log(e)}
                initialValues={{
                  email: undefined,
                }}
                ctaMode="enabled"
                persistence={{
                  connector: persistence,
                  excludeFields: ['address'],
                  strategy: 'onChange',
                }}
                customComponents={{ CustomInput }}
              >
                <NavProvider
                  onBeforeNavigate={handleBeforeNavigate}
                  onAfterNavigate={handleAfterNavigate}
                >
                  <Form>
                    <BackButton />
                    <ScreenHeading />
                    <ScreenFields />
                    <NextButton />
                  </Form>
                  <Debugger />
                </NavProvider>
              </FormProvider>
            </FormWrapper>
          </div>
        </PageWrapper>
      </ApolloProvider>
    </AddressTypeaheadProvider>
  );
};
