import * as Informant from '@updater/informant-core';

import {
  Address,
  BackButton,
  CheckBox as Checkbox,
  CheckList as Checklist,
  DateField,
  DatePickerField,
  Debugger,
  FieldHeading,
  FieldObject,
  FieldSet,
  FieldWrapper,
  Form,
  NextButton,
  PhoneNumberField,
  RadiolistField,
  ScreenContents,
  ScreenHeading,
  SecurityField,
  SelectField,
  SubmitButton,
  SwitchField,
  TextField,
  ToggleField,
  SSNField,
  FileUploader,
  CheckPillList,
} from '../../components';

export const DOMComponents: Informant.SourceComponents = {
  Address,
  BackButton,
  Checkbox,
  Checklist,
  DateField,
  DatePickerField,
  Debugger,
  FieldHeading: FieldHeading as Informant.SourceComponents['FieldHeading'],
  FieldObject,
  FieldSet,
  FieldWrapper,
  Form,
  NextButton,
  PhoneNumberField,
  RadiolistField,
  ScreenContents,
  ScreenHeading,
  SecurityField,
  SelectField,
  SubmitButton,
  SwitchField,
  TextField,
  ToggleField,
  SSNField,
  FileUploader,
  CheckPillList,
};
