import type { JSONSchema7 } from 'json-schema';
import type { PersistenceConnector } from './types/persistence-connector';
import sha256 from './lib/sha256';

const HASH_PREFIX = 'INFMT';

interface Entry {
  data: Record<string, unknown> | null;
  schemaId: string;
  startedAt: Date;
  submittedAt: Date;
}

export const LocalstoragePersistence: (
  userId: string
) => PersistenceConnector = (userId) => {
  function getKey(schema: JSONSchema7) {
    if (!schema.$id) {
      throw new Error(
        'Your schema must have an `$id` in order to use local storage persistence'
      );
    }
    const uint8array = new TextEncoder().encode(JSON.stringify(schema));
    const hashUint = sha256(uint8array);
    const hash = Buffer.from(hashUint).toString('hex');
    return `${HASH_PREFIX}:${userId}:${schema.$id}:${hash}`;
  }

  async function save(entryId: string, values: Record<string, unknown>) {
    const item = localStorage.getItem(entryId);
    if (item) {
      const entry = JSON.parse(item) as Entry;
      entry.data = {
        ...entry.data,
        ...values,
      };
      localStorage.setItem(entryId, JSON.stringify(entry));
    }

    console.warn(`Trying to save form data to an entry not found, ${entryId}`);
  }

  async function getOrCreateEntry(
    schema: JSONSchema7,
    initialValues?: Record<string, unknown>
  ) {
    // Hash against the user id and schema. This means if the user id
    // or schema change, we would create a new entry
    const key = getKey(schema);
    const item = localStorage.getItem(key);

    if (!item) {
      const entry = {
        data: initialValues || {},
        schemaId: schema.$id!,
      };

      localStorage.setItem(key, JSON.stringify(entry));
      return {
        id: key,
        data: entry.data,
      };
    }

    const entry = JSON.parse(item);

    return {
      id: key,
      data: entry!.data,
    };
  }

  return {
    save,
    getOrCreateEntry,
  };
};
