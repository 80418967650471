import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Input,
  FieldWrapper,
  FieldSet,
  Flex,
  DropDownSelect,
  Button,
  Box,
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ZipCodeInput,
  Alert,
} from '@updater/ui-uds';

import { FieldProps, Field, useField, FieldMetaProps } from 'formik';
import { useNormalizedAddressQuery } from '../use-address-normalization';
import {
  getPropertyErrors,
  TPropertyErrors,
} from '../../utils/get-property-errors';
import { ConfirmAddress } from '../confirm-address';
import { TAddress, TStates } from '../types';
import { USStateData } from '../constants';
import { compareAddresses } from '../utils';
import { AddressTypeaheadInput } from '../../components/address-typeahead-input';

const StateOptions = USStateData.map(({ name, code }) => ({
  label: name,
  value: code,
}));

interface AddressWithNormalizaion extends TAddress {
  forced?: boolean;
  normalizationResponse?: string;
}

interface AddressErrors {
  street?: string;
  unit?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  normalizationResponse?: string;
}
interface AddressWidgetProps extends TPropertyErrors {
  name: string;
  onComplete?(): void;
  continueButtonText?: string;
  submitDisabled?: boolean;
  resetFormOnSubmit?: boolean;
  setForced?: boolean;
}

function convertUndefinedToEmptyString(address: TAddress) {
  const { street, unit, city, state, postalCode } = address;

  return {
    street: street || '',
    unit: unit || '',
    city: city || '',
    state: state || '',
    postalCode: postalCode || '',
  };
}

/**
 * Should normalize - Checks that the address has been filled in by the user
 * @param {
 *   error,
 * }
 * @returns  True if there are no errors on the address and it exists, false otherwise
 */
function shouldNormalize({
  error,
}: FieldMetaProps<TAddress> | { error: AddressErrors }) {
  return (
    typeof error === 'object' &&
    typeof error.state === 'undefined' &&
    typeof error.street === 'undefined' &&
    typeof error.postalCode === 'undefined' &&
    typeof error.city === 'undefined'
  );
}

export const AddressWidget: React.FC<AddressWidgetProps> = ({
  name,
  onComplete,
  continueButtonText,
  resetFormOnSubmit,
  submitDisabled,
  allowCommercial,
  allowMilitary,
  commercialErrorMsg,
  militaryErrorMsg,
  allowInvalid,
  allowAptNeeded,
  aptNeededErrorMsg,
  invalidErrorMsg,
  setForced = false,
}) => {
  const [mode, setMode] = useState<'input' | 'readyToSelect' | 'select'>(
    'input'
  );

  const [addressField, addressMeta, helper] =
    useField<AddressWithNormalizaion>(name);

  const [normalizeAddress, { variables, error, data: normalizedAddressData }] =
    useNormalizedAddressQuery();

  const prevNormalizedAddressData = useRef(normalizedAddressData);
  // Extracts out just the address from the field
  const addressInputValue = useMemo(() => {
    if (addressField.value) {
      const { unit, street, city, postalCode, state } = addressField.value;
      return { unit, street, city, postalCode, state };
    }
    return undefined;
  }, [
    addressField?.value?.street,
    addressField?.value?.unit,
    addressField?.value?.city,
    addressField?.value?.state,
    addressField?.value?.postalCode,
  ]);
  useEffect(() => {
    if (
      addressInputValue &&
      typeof normalizedAddressData === 'undefined' &&
      error
    ) {
      // Case 1: The GQL error response has a message meaning we're likely offline
      // Let the user go to the modal and make data valid by setting
      // `normalizationResponse` so they can proceed if they want
      setMode('readyToSelect');
      helper.setValue({
        ...addressInputValue,
        ...(setForced ? { forced: true } : {}),
        normalizationResponse: JSON.stringify(addressInputValue),
      });
    }
  }, [error]);

  const prevAddressInputValue = useRef(addressInputValue);
  const isShouldNormalize = shouldNormalize(addressMeta);
  // initialUserAddress is used when a user cancels out of the ConfirmAddress Modal
  const [initialUserAddress, setInitialUserAddress] = useState({} as TAddress);

  // Checks if we already submitted the current input
  // address to be normalized
  const alreadyQueried = useMemo(() => {
    return (
      addressInputValue &&
      variables?.input &&
      compareAddresses(variables.input, addressInputValue)
    );
  }, [variables, addressInputValue]);

  // Checks property type after normalization and returns error message
  // allowMilitary or isCommertial flags are false
  const propertyError = useMemo(() => {
    return getPropertyErrors({
      data: normalizedAddressData,
      allowCommercial,
      allowMilitary,
      militaryErrorMsg,
      commercialErrorMsg,
      allowInvalid,
      allowAptNeeded,
      aptNeededErrorMsg,
      invalidErrorMsg,
    });
  }, [
    normalizedAddressData,
    allowCommercial,
    allowMilitary,
    commercialErrorMsg,
    militaryErrorMsg,
    allowInvalid,
    allowAptNeeded,
    aptNeededErrorMsg,
    invalidErrorMsg,
  ]);

  // This effect is responsible for running the normalization.
  // It will run on every change to the input address, and will
  // make sure to only run the lookup if:
  //
  // 1. We haven't already run the lookup for this address
  // 2. If there is a valid address input (sync validation)
  // 3. We're not on the select modal
  useEffect(() => {
    if (!addressInputValue) {
      return;
    }

    // Don't re run normalization if we're selecting
    if (mode === 'select') {
      return;
    }

    // Don't run normalization if we already have done so on this address
    if (alreadyQueried) {
      return;
    }
    if (isShouldNormalize) {
      normalizeAddress({
        variables: {
          input: addressInputValue,
        },
      });
    }
  }, [
    addressInputValue,
    isShouldNormalize,
    alreadyQueried,
    normalizeAddress,
    mode,
  ]);

  // This effect is responsible for executing code when we get a response back
  // from the normalization request
  useEffect(() => {
    // check if there is a new address
    const newDataLoaded =
      prevNormalizedAddressData.current !== normalizedAddressData;
    prevNormalizedAddressData.current = normalizedAddressData;

    // check if normalization field is empty and the mode is set to 'input'
    // if this is the case we likely want to set the normalization response
    // on the field value to validate the form
    const revalidateNormalizationField =
      addressField?.value?.normalizationResponse === undefined &&
      mode === 'input';

    if (
      addressInputValue &&
      normalizedAddressData &&
      alreadyQueried &&
      (newDataLoaded || revalidateNormalizationField)
    ) {
      const normalizedResponseData =
        normalizedAddressData?.normalizedAddress?.normalizedAddress;
      if (normalizedResponseData === null) {
        // Case 1: The response is null meaning we could not find the address.
        // Let the user go to the modal and make data valid by setting
        // `normalizationResponse` so they can proceed if they want
        setMode('readyToSelect');
        helper.setValue({
          ...addressInputValue,
          ...(setForced ? { forced: true } : {}),
          normalizationResponse: JSON.stringify(addressInputValue),
        });
      } else if (compareAddresses(normalizedResponseData, addressInputValue)) {
        // Case 2: The normalized and input addesses match, so we make the
        // data valid by setting `normalizationResponse`
        helper.setValue({
          ...addressInputValue,
          ...(setForced ? { forced: false } : {}),
          normalizationResponse: JSON.stringify(normalizedResponseData),
        });
        setMode('input');
      } else {
        // Case 3: The normalized and input addesses are different, so allow
        // the user to open the modal
        setMode('readyToSelect');
      }
    }
  }, [normalizedAddressData, addressInputValue, alreadyQueried, helper]);

  // This effect runs only once and is responsible for making the
  // form data invalid when there are initial values. This is
  // particularly important to catch the allowMilitary and
  // allowCommercial flags
  useEffect(() => {
    if (
      addressMeta?.initialValue?.normalizationResponse &&
      mode !== 'select' &&
      addressInputValue &&
      addressField?.value?.normalizationResponse &&
      !error
    ) {
      helper.setValue({
        ...addressInputValue,
        ...(setForced ? { forced: false } : {}),
        normalizationResponse: undefined,
      });
    }
  }, []);
  // This effect is responsible for making the form data invalid
  // when we change. This is important for the case where a user already
  // selected an addrees, but then wants to go back and change the address.
  // We need to make the form invalid to force the user to go through
  // the normalization process again.
  useEffect(() => {
    // Unit may have no min length and gets re-hydrated as null which is invalid
    if (addressInputValue && !addressInputValue.unit) {
      addressInputValue.unit = '';
    }

    const addressChanged = prevAddressInputValue.current !== addressInputValue;
    prevAddressInputValue.current = addressInputValue;

    if (
      mode !== 'select' &&
      addressInputValue &&
      addressChanged &&
      addressField?.value?.normalizationResponse &&
      !error
    ) {
      helper.setValue({
        ...addressInputValue,
        ...(setForced ? { forced: false } : {}),
        normalizationResponse: undefined,
      });
    }
  }, [
    addressInputValue,
    normalizedAddressData,
    mode,
    addressField?.value?.normalizationResponse,
    helper,
  ]);

  const handleSelectAddress = (address: TAddress) => {
    helper.setValue({
      ...address,
    });
  };
  // reset form values
  const handleReset = () => {
    helper.setValue({
      street: '',
      unit: '',
      city: '',
      state: '' as TStates,
      postalCode: '',
      ...(setForced ? { forced: false } : {}),
      normalizationResponse: undefined,
    });
    setMode('input');
  };

  // handles the AddressTypeaheadInput
  const handleConfirmAddress = (address: AddressWithNormalizaion) => {
    const newAddress = convertUndefinedToEmptyString(address);
    helper.setValue({
      ...newAddress,
      normalizationResponse: JSON.stringify(normalizedAddressData),
    });
  };
  // when a user clicks 'x' button on modal
  const handleCloseModal = () => {
    helper.setValue(initialUserAddress);
  };

  // Checks form values to get valid state
  const formValid = useMemo(() => {
    if (addressMeta.error === undefined || !addressMeta.touched) {
      return true;
    }
    const isValid =
      addressInputValue?.city !== '' &&
      addressInputValue?.street !== '' &&
      addressInputValue?.postalCode !== '';
    return isValid;
  }, [addressMeta]);

  if (mode !== 'select' || !addressInputValue) {
    return (
      <>
        <FieldSet>
          <FieldWrapper />
          {propertyError?.type === 'NONRESIDENTIAL' ? (
            <Alert variant="error" mb="s" message={propertyError.message} />
          ) : (
            ''
          )}
          <FieldWrapper>
            <Field name={`${name}.street`}>
              {({ field, meta }: FieldProps) => (
                <>
                  <AddressTypeaheadInput
                    label="Street"
                    {...field}
                    onSelectAddress={(address) => handleSelectAddress(address)}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    handleBlur={field.onBlur}
                    onReset={handleReset}
                    name={field.name}
                    value={field.value}
                    error={meta.touched && meta.error ? meta.error : undefined}
                  />
                </>
              )}
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <Field name={`${name}.unit`}>
              {({ field, meta }: FieldProps) => (
                <Input
                  label="Unit"
                  {...field}
                  error={
                    propertyError?.type === 'UNIT'
                      ? propertyError.message
                      : undefined ??
                        (meta.touched && meta.error ? meta.error : undefined)
                  }
                />
              )}
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <Field name={`${name}.city`}>
              {({ field, meta }: FieldProps) => (
                <Input
                  label="City"
                  {...field}
                  error={meta.touched && meta.error ? meta.error : undefined}
                />
              )}
            </Field>
          </FieldWrapper>
          <FieldWrapper width={0.5}>
            <Field name={`${name}.state`}>
              {({ field, meta }: FieldProps) => (
                <DropDownSelect
                  label="State"
                  options={StateOptions}
                  {...field}
                  error={meta.touched && meta.error ? meta.error : undefined}
                />
              )}
            </Field>
          </FieldWrapper>

          <FieldWrapper width={0.5}>
            <Field name={`${name}.postalCode`}>
              {({ field, meta }: FieldProps) => (
                <ZipCodeInput
                  label="Zip"
                  id="address-postalCode"
                  {...field}
                  error={meta.touched && meta.error ? meta.error : undefined}
                />
              )}
            </Field>
          </FieldWrapper>
          <Box flex="0 0 100%" mt="m">
            {mode === 'readyToSelect' ? (
              <Button
                onClick={() => {
                  setMode('select');
                }}
                isFluidWidth
                size="l"
                disabled={!formValid || propertyError?.disableNext}
              >
                Check address
              </Button>
            ) : (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  if (onComplete) {
                    onComplete();
                  }

                  if (resetFormOnSubmit) {
                    handleReset();
                  }
                }}
                disabled={
                  addressMeta.error !== undefined ||
                  submitDisabled ||
                  propertyError?.disableNext
                }
                size="l"
                isFluidWidth
              >
                {continueButtonText || 'Next'}
              </Button>
            )}
          </Box>
        </FieldSet>
      </>
    );
  }

  return (
    <>
      <Modal isOpen setIsOpen={() => setMode('readyToSelect')}>
        <ModalOverlay handleClick={handleCloseModal} />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton onClick={handleCloseModal} />
          </ModalHeader>
          <ModalBody flexDirection="column">
            <ConfirmAddress
              normalizedAddress={
                normalizedAddressData?.normalizedAddress?.normalizedAddress
              }
              userAddress={addressField.value}
              onChange={handleConfirmAddress}
              setInitialUserAddress={setInitialUserAddress}
            />

            <Flex mt="m" justifyContent="space-between">
              {onComplete ? (
                <>
                  {!normalizedAddressData?.normalizedAddress
                    ?.normalizedAddress || error ? (
                    <Box flex="1" mr="s">
                      <Button
                        onClick={() => setMode('readyToSelect')}
                        variant="secondary"
                        size="l"
                        isFluidWidth
                      >
                        Edit
                      </Button>
                    </Box>
                  ) : null}
                  <Box flex="1">
                    <Button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        if (onComplete) {
                          onComplete();
                        }
                      }}
                      size="l"
                      isFluidWidth
                    >
                      Use Address
                    </Button>
                  </Box>
                </>
              ) : null}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
