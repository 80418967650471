import React from 'react';
import { useFormikContext } from 'formik';
import update from 'lodash/update';
import { useForm, useFormNav, SourceComponents } from '@updater/informant-core';
import { Button, Spinner } from '@updater/ui-uds';

export const NextButton: SourceComponents['NextButton'] = (props) => {
  const {
    canGoNext,
    goNext,
    isLastScreen,
    currentScreenConfig,
    currentScreen,
  } = useFormNav();
  const { isSubmitting, isValid, submitForm, setTouched, validateForm } =
    useFormikContext();
  const { trackEvent, ctaMode } = useForm();

  const handleClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    // We just submit on the last screen. Formiik automatically sets the fields to touched
    // in this scenario. Please note you need to make sure initial values are there for the
    // touched scenario to work.
    if (isLastScreen) {
      await submitForm();
      return;
    }

    // Iterate through all the field on the current screen and set them to touched,
    // so that we show all the errors on the current screen
    // Note: this does not handle nested fields
    const touchedObject = currentScreenConfig?.fields.reduce((acc, curr) => {
      update(acc, curr, () => true);
      return acc;
    }, {} as Record<string, unknown>);

    setTouched(touchedObject);
    // We validate the form because there may be fields where auto validation is turned
    // off
    await validateForm();

    if (isLastScreen) {
      await submitForm();
    } else if (canGoNext()) {
      goNext();
    }
  };

  if (currentScreenConfig?.hideNextButton) {
    return null;
  }

  // If the user is in the last screen, the button should be disabled only
  // when the form is not valid, otherwise the button should act as a submit button
  const buttonEnabled =
    ctaMode === 'enabled' || (isLastScreen ? isValid : canGoNext());

  return (
    <Button
      data-cy="form-next"
      onClick={handleClick}
      disabled={!buttonEnabled}
      size="l"
      isFluidWidth
      name="next"
      track={(metadata) =>
        trackEvent?.({
          object: 'nextButton',
          pageName: currentScreen,
          ...metadata,
        })
      }
      {...props}
    >
      {isSubmitting ? (
        <div>
          <Spinner color="light" />
        </div>
      ) : (
        currentScreenConfig?.nextButtonText || 'Next'
      )}
    </Button>
  );
};
