import { SuperSchema } from '../types';

export const AddressSinglePageSubSchema: SuperSchema = {
  title: 'Address',
  type: 'object',
  properties: {
    street: {
      title: 'Street',
      type: 'string',
      minLength: 1,
    },
    unit: {
      type: 'string',
    },
    city: {
      type: 'string',
      minLength: 1,
    },
    postalCode: {
      type: 'string',
      minLength: 5,
    },
    state: {
      type: 'string',
      minLength: 2,
    },
    normalizationResponse: {
      type: 'string',
    },
    forced: {
      type: 'boolean',
    },
  },
  required: ['street', 'city', 'postalCode', 'state', 'normalizationResponse'],
  ui: {
    component: 'address',
    componentProps: {
      continueButtonText: 'Save',
    },
  },
};
