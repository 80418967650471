import { useFormNav, SourceComponents } from '@updater/informant-core';
import { H1, Text, Box } from '@updater/ui-uds';

export const ScreenHeading: SourceComponents['ScreenHeading'] = () => {
  const { currentScreenConfig } = useFormNav();
  if (currentScreenConfig) {
    return (
      <Box mb="m">
        <H1 as="h1" variant={['xxl', 'xxl', 'xxxl', 'xxxl']}>
          {currentScreenConfig.heading}
        </H1>
        {currentScreenConfig.subHeading && (
          <Text
            variant={['m']}
            // TODO: why is this hard-coded to 330px?
            style={{ whiteSpace: 'pre-line', maxWidth: '330px' }}
            mt="xs"
          >
            {currentScreenConfig.subHeading}
          </Text>
        )}
      </Box>
    );
  }
  return null;
};
