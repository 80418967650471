import { LayoutSchemaScreen } from '../types';

type ScreenProps = {
  screens: LayoutSchemaScreen[];
  path: string;
};

/**
 * Gets the screen from the LayoutSchema which is represented by the current
 * route
 * @param screen The screens of a LayoutSchema
 * @param path The current path(minus the base path)
 */
export const getScreenFromRoute = ({
  screens,
  path,
}: ScreenProps): [LayoutSchemaScreen | null, number] => {
  if (!screens || screens?.length === 0) {
    throw Error(
      'Unable to find screen related to route, layoutSchema is empty.'
    );
  }

  const index = screens.findIndex(
    ({ name, route }) => route === path || name === path
  );

  if (index === -1) {
    console.warn(
      `Screen with a route ${path} cannot be matched to any of the screens in the layout schema:\n ${screens
        .map(({ route, name }) => `Route: ${route}, Name: ${name}`)
        .join(
          '\n'
        )}. Please check your layout schema routes, and your basePath, and make sure the url you are currently accessing matches the configuration.`
    );
  }

  return [index === -1 ? null : screens[index], index];
};
