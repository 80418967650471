/**
 * @file An example of how to add custom components to a form.
 */
import {
  Form,
  FormProvider,
  SuperSchema,
  CustomComponentProps,
} from '@updater/ui-informant';
import React, { useCallback } from 'react';
import { Text, Flex } from '@updater/ui-uds';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    specialInput: {
      title: 'Special Input',
      type: 'number',
      ui: {
        component: 'SpecialInput',
        messages: {
          required: '',
        },
      },
    },
  },
  required: ['specialInput'],
};

type FormData = {
  email: string;
};

const initialValues: FormData = {
  email: '',
};

const SpecialInput: React.FC<CustomComponentProps> = (props) => {
  const { onChange, value, error } = props;

  const onChangeInput = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <Flex flexDirection="column">
      <Text as="h4">Special Input</Text>
      {error && <Text as="p">{error}</Text>}
      <input onChange={onChangeInput} value={value} />
    </Flex>
  );
};

const customComponents = {
  SpecialInput,
};

export const MyForm: React.FC = () => {
  const handleSubmit = useCallback((values: FormData) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  }, []);

  return (
    <>
      <FormProvider<FormData>
        schema={schema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        customComponents={customComponents}
      >
        <Form />
      </FormProvider>
    </>
  );
};

export default MyForm;
