import { useFormikContext } from 'formik';

import React, { useEffect, useRef } from 'react';
import omit from 'lodash/omit';
import { useForm } from '../../context/form-provider/FormContext';
import { PersistenceSettings } from '../../types/persistence-connector';
// import { SuperSchema } from '../..';
import { stripUi } from '../../lib/strip-ui';

interface Props {
  settings: PersistenceSettings;
}

export const PersistenceHandler: React.FC<Props> = ({
  settings: { connector, excludeFields = [] },
}) => {
  const { srcSchema } = useForm();

  const { values } = useFormikContext();
  const entryId = useRef<string | null>(null);

  useEffect(() => {
    const init = async () => {
      const strippedSchema = stripUi(srcSchema);
      const entry = await connector.getOrCreateEntry(strippedSchema);
      entryId.current = entry.id;
    };
    init();
  }, []);

  useEffect(() => {
    // For now will save on every change. Will be optimized when we implement persistence strategies

    if (entryId.current) {
      const filteredValues = omit(
        values as Record<string, unknown>,
        excludeFields
      );
      connector.save(entryId.current, filteredValues);
    }
  }, [values]);

  return null;
};
