import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import styled from '@emotion/styled';
import { Box, Button } from '@updater/ui-uds';

const TextArea = styled('textarea')`
  width: 100%;
  height: 300px;
`;
export const SchemaRenderer: React.FC<{
  json: unknown;
  onChange?: (x: Record<string, unknown>) => void;
}> = ({ json, onChange }) => {
  const [editing, setEditing] = useState(false);
  const [stringjson, setStringJson] = useState(JSON.stringify(json, null, 2));

  return (
    <Box>
      <Box p="m" />
      {editing ? (
        <TextArea
          value={stringjson}
          onChange={({ target: { value } }) => setStringJson(value)}
        />
      ) : (
        <ReactJson
          indentWidth={2}
          src={json as object}
          theme="summerfruit:inverted"
        />
      )}
      {onChange && (
        <Box mt="m">
          <Button
            type="button"
            variant="secondary"
            size="s"
            onClick={() => {
              if (editing) {
                onChange(JSON.parse(stringjson));
              }
              setEditing(!editing);
            }}
          >
            {editing ? 'Save' : 'Edit'}
          </Button>
        </Box>
      )}
    </Box>
  );
};
