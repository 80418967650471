import { NormalizedAddressQueryResponse } from '../address/use-address-normalization';

export interface TPropertyErrors {
  /**
   * Disables form when a commercial address is recieved
   * and allowCommercial is set to false.
   * Defaults to true
   */
  allowCommercial?: boolean;
  /**
   * Disables form when a military address is recieved
   * and allowMilitary is set to false.
   * Defaults to true
   */
  allowMilitary?: boolean;
  /**
   * Disables form when an an address that needs an apartnment doesn't have one
   * and and allowAptNeeded set to false.
   * Defaults to true
   */
  allowAptNeeded?: boolean;
  /**
   * Disables form when an address is invalid and and allowInvalid is set to
   * false.
   * Defaults to true
   */
  allowInvalid?: boolean;
  /**
   * sets error message when commercial address is recieved
   * and allowCommercial is set to false.
   * Defaults to undefined
   */
  commercialErrorMsg?: string;
  /**
   * sets error message when military address is recieved
   * and allowMilitary is set to false.
   * Defaults to undefined
   */
  militaryErrorMsg?: string;
  /**
   * sets error message when address that needs an apartnment is entered
   * and allowAptNeeded  set to false.
   * Defaults to undefined
   */
  aptNeededErrorMsg?: string;
  /**
   * sets error message when address is invalid
   * and allowInvalid set to false.
   * Defaults to undefined
   */
  invalidErrorMsg?: string;
}

export const getPropertyErrors = ({
  data,
  allowCommercial = true,
  allowMilitary = true,
  allowAptNeeded = true,
  allowInvalid = true,
  militaryErrorMsg,
  commercialErrorMsg,
  aptNeededErrorMsg,
  invalidErrorMsg,
}: TPropertyErrors & { data?: NormalizedAddressQueryResponse }):
  | { type: string; message: string; disableNext: boolean }
  | undefined => {
  if (data?.normalizedAddress) {
    const { normalizedAddress } = data;
    if (
      normalizedAddress?.normalizedAddress?.classification === 'COMMERCIAL' &&
      !allowCommercial
    )
      return {
        type: 'NONRESIDENTIAL',
        message:
          commercialErrorMsg ??
          'You cannot forward mail to or from a commercial address. If you believe this is a mistake, email help@updater.com.',
        disableNext: !allowCommercial,
      };

    if (
      normalizedAddress?.normalizedAddress?.classification === 'MILITARY' &&
      !allowMilitary
    )
      return {
        type: 'NONRESIDENTIAL',
        message:
          militaryErrorMsg ??
          'You cannot forward mail to or from a military address. If you believe this is a mistake, email help@updater.com.',
        disableNext: !allowMilitary,
      };

    if (normalizedAddress?.normalizedAddress?.status === 'APT_NEEDED') {
      const defaultMessage = allowAptNeeded
        ? 'Enter apt number. Not sure? Click Next and add it later.'
        : 'This address requires an apartment number.';
      return {
        type: 'UNIT',
        message: aptNeededErrorMsg ?? defaultMessage,
        disableNext: !allowAptNeeded,
      };
    }

    if (normalizedAddress?.normalizedAddress?.status === 'INVALID') {
      const defaultMessage = allowAptNeeded
        ? 'Unit not found. Re-enter or click Next to add it later.'
        : 'Unit not found. Please enter a unit.';
      return {
        type: 'UNIT',
        message: invalidErrorMsg ?? defaultMessage,
        disableNext: !allowInvalid,
      };
    }
  }

  return undefined;
};
