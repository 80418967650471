import { SuperSchema } from '../types';

const stripUiRecursive = (schema: SuperSchema) => {
  const newSchema = { ...schema };

  if (newSchema.ui) {
    delete newSchema.ui;
  }

  // Recurse properties
  if (newSchema.properties) {
    newSchema.properties = Object.entries(newSchema.properties).reduce(
      (acc, curr) => {
        const [key, subSchema] = curr;
        acc[key] = stripUiRecursive(subSchema);
        return acc;
      },
      {} as { [s: string]: SuperSchema }
    );
  }

  // Recurse items
  if (schema.items) {
    if (Array.isArray(schema.items)) {
      throw new Error(
        'stripUiRecursive: Item defined as arrays currently unsupported.'
      );
    } else {
      newSchema.items = stripUiRecursive(schema.items);
    }
  }

  // Recurse allof
  if (newSchema.allOf) {
    newSchema.allOf = newSchema.allOf.map((subSchema) =>
      stripUiRecursive(subSchema)
    );
  }

  // Recurse oneOf
  if (newSchema.oneOf) {
    newSchema.oneOf = newSchema.oneOf.map((subSchema) =>
      stripUiRecursive(subSchema)
    );
  }

  // Recurse thens
  if (newSchema.then) {
    newSchema.then = stripUiRecursive(newSchema.then);
  }

  // Recurse else
  if (newSchema.else) {
    newSchema.else = stripUiRecursive(newSchema.else);
  }

  return newSchema;
};

/**
 * Removes all the `ui` properties from a schema
 * for the purposes of caching purely against the data schema.
 *
 * This means we can make changes to ui properties with affecting persistence.
 */
export const stripUi = (schema: SuperSchema): SuperSchema => {
  return stripUiRecursive(schema);
};
