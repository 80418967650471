import React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider, Box, Text, Flex } from '@updater/ui-uds';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import FormGenerator from './components/FormGenerator/FormGenerator';
import { Examples } from './components/Examples';
import { ExamplePage } from './components/ExamplePage';
import { ExampleFlow } from './components/ExampleFlow';

const Wrapper = styled.div`
  text-align: left;
`;

const NavItem = styled(NavLink)`
  text-align: left;
  text-decoration: none;
  color: #fff;
  display: block;
  &.active {
    border-bottom: 2px solid #fff;
  }
`;

const Logo = styled.img`
  width: 30px;
  height: auto;
  margin-right: 15px;
`;

const LinkBox: React.FC<{ url: string }> = ({ url, children }) => {
  return (
    <Box p="m">
      <NavItem to={url}>
        <Text variant="mBold" color="#FFF">
          {children}
        </Text>
      </NavItem>
    </Box>
  );
};
function App() {
  return (
    <ThemeProvider>
      <Wrapper>
        <BrowserRouter>
          <Box as="nav" bg="brand" display="flex" height="72px">
            <Flex display="flex" alignItems="center" mx="m">
              <Logo src="/favicon.png" />
              <Text color="#FFF" variant="xlBold">
                Informant Playground
              </Text>
            </Flex>
            <LinkBox url="/">Home</LinkBox>
            <LinkBox url="/examples">Examples</LinkBox>
            <LinkBox url="/example-flow/screen1">Example Flow</LinkBox>
          </Box>
          <Routes>
            <Route path="/" element={<FormGenerator />} />
            <Route path="/example-flow/*" element={<ExampleFlow />} />
            <Route path="examples" element={<Examples />}>
              <Route path=":exampleName/*" element={<ExamplePage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Wrapper>
    </ThemeProvider>
  );
}

export default App;
