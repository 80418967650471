import { SuperSchema } from '../types';
/**
 * Converts "one of" properties from the JSON schema into
 * ui properties we can pass as options to our widget
 * @param oneOfs
 * @returns A list of options usable by our widget
 */
export function optionsFromOneOf(oneOfs: Array<SuperSchema>) {
  return oneOfs.map((option) => {
    if (option.title === undefined || option.const === undefined) {
      throw new Error('We require oneOf to have a title and const defined');
    }

    return {
      label: option.title,
      value: option.const as string,
      id: option.const as string,
    };
  });
}
