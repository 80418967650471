/**
 * @file An example of how to use the date picker input component.
 */
import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    phone: {
      type: 'string',
      default: '16166127694',
      ui: {
        component: 'phone',
        label: 'Phone Number',
        width: 0.5,
      },
    },
  },
};

export const MyForm = () => {
  const handleSubmit = (values: unknown) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };

  return (
    <>
      <FormProvider schema={schema} onSubmit={handleSubmit}>
        <Form />
      </FormProvider>
    </>
  );
};
