import { useContext, FC } from 'react';

import {
  SourceComponentContext,
  SourceComponents,
  SourceComponentName,
} from './source-components';

/**
 * Returns the respective component,
 *  or calls for its configured fallback
 */
export function useSourceComponent<Name extends SourceComponentName>(
  name: Name
): SourceComponents[Name] {
  const context = useContext(SourceComponentContext);
  const component =
    context[name] ??
    context?.fallback?.(name, context) ??
    defaultFallback?.(name, context);

  return component;
}

/**
 * A cross platform fallback to support gradual adoption
 *
 * In practice this method provides a means for testing informant/core
 * core without the original components of ui-informant
 */
const defaultFallback: SourceComponents['fallback'] = (name) => {
  const fallback: FC = ({ children }) => <>{children}</>;

  return fallback as unknown as SourceComponents[typeof name];
};
