import { FormikValues } from 'formik';
import { SuperSchema } from '../types';

/**
 *
 * @param currentValues
 * @param schema
 *
 * function checks if current form values have any properties that
 * aren't in the schema and if true, will return a clean Values object
 * or null if value removal wasn't necessary
 * @returns values | null
 */

export const removeUnusedFormValues = (
  currentValues: FormikValues,
  schema: SuperSchema
): FormikValues => {
  const cleanValues = Object.keys(currentValues).reduce((newValuesObj, key) => {
    if (
      currentValues[key] &&
      typeof schema?.properties?.[key] === 'undefined'
    ) {
      const valuesCopy = { ...currentValues };
      delete valuesCopy[key];
      Object.assign(newValuesObj, valuesCopy);
    }
    return newValuesObj;
  }, {});

  /**
   * if there are values then currentValues had extraneous values  */
  if (Object.keys(cleanValues).length > 0) {
    return cleanValues;
  }
  return currentValues;
};
