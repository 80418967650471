// Informant Example
// ***
// This shows the simplest usage of Informant

import {
  Form,
  FormProvider,
  SuperSchema,
  AddressSinglePageSubSchema,
} from '@updater/ui-informant';
import { ApolloProvider } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AddressTypeaheadProvider } from '@updater/ui-widgets';
import { makeClient } from '@updater/ui-utilities';

const client = makeClient({
  requestHeaders: () => ({
    name: 'informant-playground',
    app: 'mover',
  }),
  url: 'https://api.staging.updater.com/graphql',
});

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    address: {
      ...AddressSinglePageSubSchema,
      ...{
        ui: {
          component: 'address',
          componentProps: {
            allowCommercial: false,
            allowMilitary: false,
            allowInvalid: false,
            commercialErrorMsg: "I don't think you should do that",
          },
        },
      },
    },
  },
  required: ['address'],
};

export const MyForm = () => {
  const handleSubmit = async (values: unknown) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };

  return (
    <>
      <AddressTypeaheadProvider
        value={{
          GOOGLE_PLACES_API_KEY: process.env
            .REACT_APP_GOOGLE_PLACES_API_KEY as string,
        }}
      >
        <ApolloProvider client={client as any}>
          <FormProvider schema={schema} onSubmit={handleSubmit}>
            <Form />
          </FormProvider>
        </ApolloProvider>
      </AddressTypeaheadProvider>
    </>
  );
};
