import { createContext } from 'react';
import { LayoutSchemaScreen } from '../../types';

export type NavContextValue = {
  currentScreen: string;
  currentScreenConfig: LayoutSchemaScreen;
  currentScreenIndex: number;
  isLastScreen: boolean;
  canGoNext: () => boolean;
  goNext({ replace, force }?: { replace?: boolean; force?: boolean }): void;
  canGoBack: () => boolean;
  goBack({ replace, force }?: { replace?: boolean; force?: boolean }): void;
  goToScreen(screen: string): void;
  goToLatestScreen(): void;
  getLatestScreen(): string;
  screens: LayoutSchemaScreen[];
};

export const NavContext = createContext<NavContextValue>({} as NavContextValue);
