import { Validator, Schema } from 'jsonschema';
import { SuperSchema, UISchema } from '../types';
import { optionsFromOneOf } from './options-from-one-of';

const validator = new Validator();

/**
 * Build a super schema from a input super schema
 * by autofilling the missing ui props.
 *
 * We do this so to "cache" calculated properties prior
 * to the form being mounted to save having to do those
 * calculations while the user is interacting.
 * @param schema An input super schema or json schema
 */
export function buildSuperSchema(
  schema: SuperSchema,
  initialValue?: string | Record<string, unknown> | SuperSchema
): SuperSchema {
  const newSchema = { ...schema };

  // Prefill the 'component' attribute

  if (newSchema.ui === undefined) {
    newSchema.ui = {} as UISchema;
  }

  /** Dynamically hide fields if hideIfExisting or hide ui prop is true.
   *  If initialValue is not a valid value, don't hide so user has a chance to fix.
   */
  if ((schema.ui?.hideIfExisting || schema.ui?.hide) && initialValue) {
    const result = validator.validate(initialValue, schema as Schema);

    if (result.errors.length > 0) {
      console.warn(
        `A hidden existing form value is not passing validation:  ${result.errors.join()}`
      );
      newSchema.ui.hide = false;
    } else {
      newSchema.ui.hide = true;
    }
  }
  // There's no component, so make a guess based on
  // the data schema
  if (newSchema.ui.component === undefined) {
    // 1. Array types
    if (schema.type === 'array') {
      const { items } = schema;
      // Array of strings with a oneOf is a checklist
      if (
        items &&
        !Array.isArray(items) &&
        items.type === 'string' &&
        items.oneOf
      ) {
        newSchema.ui.component = 'checklist';
        newSchema.ui.options = optionsFromOneOf(items.oneOf);
      }
    } else if (schema.type === 'boolean') {
      // Checkbox for booleans
      newSchema.ui.component = 'checkbox';
    } else if (schema.oneOf) {
      // Select for one ofs
      newSchema.ui.component = 'select';
      newSchema.ui.options = optionsFromOneOf(schema.oneOf);
    } else if (schema.type !== 'object') {
      newSchema.ui.component = 'text';
    }
  } else if (
    newSchema.ui.component === 'radiolist' ||
    newSchema.ui.component === 'select'
  ) {
    if (newSchema.oneOf !== undefined) {
      newSchema.ui.options = optionsFromOneOf(newSchema.oneOf);
    } else {
      throw new Error(
        'We require "oneOf" property in the schema to be set for field'
      );
    }
  }

  // Make sure number <input> fields are rendered with type="number"
  if (schema.type === 'number' && !schema.ui?.inputType) {
    newSchema.ui.inputType = 'number';
  }

  // Recurse properties
  if (schema.properties) {
    newSchema.properties = Object.entries(schema.properties).reduce(
      (acc, curr) => {
        const [key, subSchema] = curr;
        const subInitialValue = initialValue
          ? (initialValue as { [s: string]: SuperSchema })[key]
          : undefined;
        acc[key] = buildSuperSchema(subSchema, subInitialValue);
        return acc;
      },
      {} as { [s: string]: SuperSchema }
    );
  }

  // Recurse items
  if (schema.items) {
    if (Array.isArray(schema.items)) {
      throw new Error('Item defined as arrays currently unsupported.');
      // TODO: Currently we do not support schemas with array configurations for the items
      // newSchema.items = schema.items.map((items) => buildSuperSchema(items));
    } else {
      newSchema.items = buildSuperSchema(schema.items);
    }
  }

  // Recurse then
  if (schema.then) {
    newSchema.then = buildSuperSchema(schema.then as SuperSchema, initialValue);
  }
  if (schema.allOf) {
    newSchema.allOf = schema.allOf?.map((subSchema) => {
      return buildSuperSchema(subSchema as SuperSchema, initialValue);
    });
  }

  return newSchema;
}
