import { SourceComponents } from '@updater/informant-core';
import { LabeledCheckbox, Palette, Text } from '@updater/ui-uds';

export const Checkbox: SourceComponents['Checkbox'] = ({
  children,
  mode = 'subtle',
  label,
  ...rest
}) => {
  return (
    <>
      {mode === 'normal' ? (
        <LabeledCheckbox label={label} {...rest}>
          {children}
        </LabeledCheckbox>
      ) : (
        <LabeledCheckbox
          {...rest}
          label={
            <Text variant="s" color={Palette.gray40} ml="s" mb="">
              {label}
            </Text>
          }
          size="m"
        />
      )}
    </>
  );
};

export { Checkbox as CheckBox };
