import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { FormikErrors } from 'formik';
import { LayoutSchemaScreen } from '../types';

type ScreenProps = {
  screen: LayoutSchemaScreen;
  errors?: FormikErrors<unknown>;
};

/**
 * Calculate if a subset of a schema is valid or not
 * @param errors the errors of the form
 * @param screen An input LayoutSchema
 */

export const isSubschemaValid = ({ screen, errors }: ScreenProps): boolean => {
  if (!screen) return false;
  const { fields } = screen;

  const screenErrors = pick(errors, fields);
  return isEmpty(screenErrors);
};
