import { SourceComponents } from '@updater/informant-core';

import { H2, Text } from '@updater/ui-uds';
import React from 'react';

type Props = React.ComponentProps<SourceComponents['FieldHeading']> & {
  /**
   * @deprecated this is a mispelling in the original spec, fixed in
   * informant/core and maintained in informant/web for compatability
   *
   * https://www.google.com/search?q=define+subheading
   */
  subHeading: string;
};

export const FieldHeading: React.FC<Props> = ({ heading, subHeading }) => {
  return (
    <>
      {heading ? (
        <H2 as="h2" mb="m" variant={['l', 'l', 'xl']}>
          {heading}
        </H2>
      ) : null}

      {subHeading ? (
        <Text variant="m" mb="m">
          {subHeading}
        </Text>
      ) : null}
    </>
  );
};
