import React, { ComponentProps } from 'react';
import { ArrowLeft } from 'phosphor-react';
import { useFormikContext } from 'formik';
import { useForm, useFormNav, SourceComponents } from '@updater/informant-core';
import { Box, IconButton } from '@updater/ui-uds';

type Props = {
  caption?: string;
  onGoBack?: (
    currentScreen: string,
    goToScreen: (screen: string) => void
  ) => void;
} & ComponentProps<typeof IconButton>;

export const BackButton: SourceComponents['BackButton'] & React.FC<Props> = ({
  caption,
  ...props
}) => {
  const { canGoBack, goBack, currentScreenConfig, currentScreen, goToScreen } =
    useFormNav();
  const { trackEvent } = useForm();
  const { isSubmitting } = useFormikContext();

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    goBack();
    props?.onGoBack?.(currentScreen, goToScreen);
  };

  if (currentScreenConfig?.hideBackButton) {
    return null;
  }

  return (
    <Box maxWidth={312} mb="s">
      <IconButton
        data-cy="form-back"
        onClick={handleClick}
        disabled={!canGoBack() || isSubmitting}
        size="s"
        icon={<ArrowLeft />}
        variant="secondary"
        type="button"
        name="back"
        track={(metadata) =>
          trackEvent?.({
            object: 'backButton',
            pageName: currentScreen,
            ...metadata,
          })
        }
        {...props}
      >
        {caption || 'Next'}
      </IconButton>
    </Box>
  );
};
