import { FormikErrors } from 'formik';
import { isSubschemaValid } from './is-subschema-valid';
import { LayoutSchemaScreen } from '../types';

interface CanGoNextProps {
  currentScreenName: string;
  screens: LayoutSchemaScreen[];
  errors?: FormikErrors<unknown>;
}

/**
 * Calculates if the user can go next or not on a LayoutSchema form
 * @param currentScreenName The name of the current screen
 * @param screen The screens of a LayoutSchema
 * @param errors The errors of the form
 */

export const canGoNext = ({
  currentScreenName,
  screens = [],
  errors,
}: CanGoNextProps): boolean => {
  const currentScreenIndex = screens?.findIndex(
    (el) => el.name === currentScreenName
  );

  if (currentScreenIndex === -1) {
    console.warn('Screen you were trying to access does not exist');
    return false;
  }

  if (currentScreenIndex >= screens.length - 1) return false;
  const screen = screens[currentScreenIndex];

  const isValid = isSubschemaValid({
    screen,
    errors,
  });

  return isValid;
};
