// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormikContext } from 'formik';
import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    wantsTvService: {
      title: 'Include TV service',
      type: 'boolean',
      ui: {
        label: 'I would also like a TV service',
      },
    },
  },
  if: {
    properties: {
      wantsTvService: {
        const: true,
      },
    },
  },
  then: {
    properties: {
      tvChannels: {
        type: 'string',
        oneOf: [
          {
            const: 'discovery',
            title: 'Discovery',
          },
          {
            const: 'HBO',
            title: 'HBO',
          },
        ],
        ui: {
          label: 'Choose channel',
        },
      },
    },
    required: ['tvChannels'],
  },
  required: ['wantsTvService'],
};

const Errors: React.FC = () => {
  const { errors } = useFormikContext();
  return <div>{JSON.stringify(errors, null, 2)}</div>;
};

type TemporaryValueType = {
  wantsTvService: boolean;
};

// important that this is defined outside the scope of the function -- or it can use `useMemo`
const initialValues: TemporaryValueType = {
  wantsTvService: false,
};

export const MyForm = () => {
  const handleSubmit = (values: unknown) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };

  return (
    <FormProvider
      schema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      onChange={(e: TemporaryValueType) => console.log('onChange', e)}
    >
      <Form />
      <Errors />
    </FormProvider>
  );
};
