import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Palette } from '../../constants/palette';
import { ControlProps } from '../../types/control-types';

export type CheckboxSize = 's' | 'm' | 'l';
export type CheckboxColor = 'green' | 'blue' | 'white';
type CheckboxVariant = 'square' | 'circle';

export const checkboxSizes: {
  [size in CheckboxSize]: number;
} = {
  s: 16,
  m: 24,
  l: 40,
};

type CheckmarkIconColor = 'white' | 'blue' | 'gray';

/**
 * Possible colors of the checkmark inside the checkbox.
 */
const checkmarkIconColor: {
  [color in CheckmarkIconColor]: string;
} = {
  white: Palette.white,
  blue: Palette.blue,
  gray: Palette.gray90,
};

/**
 * Returns the URL for the background image of the checkbox for a given size
 * and color.
 *
 * @param size - Size of the checkmark.
 * @param color - Color of the checkmark.
 */
const getCheckmarkIconVariants = (
  size: CheckboxSize,
  color: CheckmarkIconColor
): string => {
  switch (size) {
    case 'm':
      return `"data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0004 1L4.00013 7L1 4.00013' stroke='${encodeURIComponent(
        checkmarkIconColor[color]
      )}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A"`;
    case 'l':
      return `"data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.6672 1L6.66673 11L1.6665 6.00022' stroke='${encodeURIComponent(
        checkmarkIconColor[color]
      )}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E"`;

    case 's':
    default:
      return `"data:image/svg+xml,%3Csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.66677 1L3.66659 5L1.6665 3.00009' stroke='${encodeURIComponent(
        checkmarkIconColor[color]
      )}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A"`;
  }
};

/**
 * Background images for the background of the checkmark for every size and
 * color variant.
 */
const checkmarkIcon: {
  [color in CheckboxColor]: {
    [size in CheckboxSize]: string;
  };
} = {
  green: {
    s: getCheckmarkIconVariants('s', 'white'),
    m: getCheckmarkIconVariants('m', 'white'),
    l: getCheckmarkIconVariants('l', 'white'),
  },
  blue: {
    s: getCheckmarkIconVariants('s', 'white'),
    m: getCheckmarkIconVariants('m', 'white'),
    l: getCheckmarkIconVariants('l', 'white'),
  },
  white: {
    s: getCheckmarkIconVariants('s', 'blue'),
    m: getCheckmarkIconVariants('m', 'blue'),
    l: getCheckmarkIconVariants('l', 'blue'),
  },
};

/**
 * Background images for the background of the disabled checkmark for every size
 * and color variant.
 */
const checkmarkIconDisabled: {
  [color in CheckboxColor]: {
    [size in CheckboxSize]: string;
  };
} = {
  green: {
    s: getCheckmarkIconVariants('s', 'white'),
    m: getCheckmarkIconVariants('m', 'white'),
    l: getCheckmarkIconVariants('l', 'white'),
  },
  blue: {
    s: getCheckmarkIconVariants('s', 'white'),
    m: getCheckmarkIconVariants('m', 'white'),
    l: getCheckmarkIconVariants('l', 'white'),
  },
  white: {
    s: getCheckmarkIconVariants('s', 'gray'),
    m: getCheckmarkIconVariants('m', 'gray'),
    l: getCheckmarkIconVariants('l', 'gray'),
  },
};

/**
 * Width of the checkmark as a percentage of the checkbox width.
 */
const checkmarkSizes: {
  [size in CheckboxSize]: number;
} = {
  s: 50,
  m: 55,
  l: 55,
};

const checkboxColors: {
  [size in CheckboxColor]: Palette;
} = {
  green: Palette.goGreen,
  blue: Palette.blue,
  white: Palette.white,
};

const checkboxColorsDisabled: {
  [size in CheckboxColor]: Palette;
} = {
  green: Palette.gray90,
  blue: Palette.gray90,
  white: Palette.white,
};

const checkboxBorderRadius: {
  [variant in CheckboxVariant]: number;
} = {
  square: 2,
  circle: Number.MAX_SAFE_INTEGER,
};

interface CheckboxTagProps {
  // Using `elementSize` instead of `size` to avoid overlap with `input`'s
  // native prop.
  elementSize: CheckboxSize;
  color: CheckboxColor;
  variant?: CheckboxVariant;
}

const getCheckboxDimensionStyles = (size: CheckboxSize) => css`
  height: ${checkboxSizes[size]}px;
  width: ${checkboxSizes[size]}px;
`;

const CheckboxTag = styled.input<CheckboxTagProps>`
  ${({ elementSize }) => getCheckboxDimensionStyles(elementSize)};
  background-color: #fff;
  flex-shrink: 0;
  appearance: none;

  border: 1px solid ${Palette.gray80};
  border-radius: ${({ variant }) =>
    checkboxBorderRadius[variant ?? 'square']}px;

  background-image: url(${({ elementSize, color }) =>
    checkmarkIcon[color][elementSize]});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 0;
  margin: 0;
  cursor: pointer;

  transition-property: background-size, border, background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease;

  &:disabled {
    border-color: ${Palette.gray90};
    background-image: url(${({ elementSize, color }) =>
      checkmarkIconDisabled[color][elementSize]});
  }

  &:checked {
    background-color: ${({ color }) => checkboxColors[color]};
    background-size: ${({ elementSize }) => checkmarkSizes[elementSize]}%;

    border-color: ${({ color }) => checkboxColors[color]};

    &:disabled {
      background-color: ${Palette.gray90};
      background-color: ${({ color }) => checkboxColorsDisabled[color]};
      border-color: ${Palette.gray90};
    }
  }
`;

export interface CheckboxProps extends ControlProps {
  /**
   * Changes the color of the checkbox.
   */
  color?: CheckboxColor;

  /**
   * Changes the size of the checkbox.
   */
  size?: CheckboxSize;

  /**
   * Changes the variant of the checkbox (rounded or square).
   */
  variant?: CheckboxVariant;
  /**
   * Adds/changes the data-cy property on the input for tracking
   */
  dataCy?: string;
}

/**
 * Checkbox
 *
 * @description Checkbox component.
 *
 * @component
 * @example
 * return (
 *   <Checkbox
 *     size="m"
 *     color="green"
 *     isChecked={true}
 *     onClick={() => { ... }}
 *   />
 * )
 */

export const Checkbox: React.FC<CheckboxProps> = ({
  size = 's',
  variant = 'square',
  color = 'green',
  dataCy,
  ...rest
}) => {
  const cyName = useMemo(() => {
    if (dataCy) {
      return dataCy;
    }
    if (rest.id) {
      return `checkbox-${rest.id}`;
    }
    if (rest.name) {
      return `checkbox-${rest.name}`;
    }
    return 'checkbox-unknown';
  }, [rest.id, rest.name, dataCy]);

  return (
    <CheckboxTag
      type="checkbox"
      color={color}
      elementSize={size}
      variant={variant}
      data-cy={cyName}
      {...rest}
    />
  );
};
