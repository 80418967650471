import { useEffect } from 'react';
import * as Informant from '@updater/informant-core';
import { DOMComponents } from './dom-components';

export const DOMComponentProvider: React.FC = (props) => {
  useEffect(() => {
    console.warn(
      'This is a prerelease version of ui informant. Please make sure your usage is intentional.'
    );
  }, []);
  return <Informant.ComponentProvider {...props} components={DOMComponents} />;
};
