// Informant Example
// ***
// This shows how to persist form data to local storage

import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';

import { LocalstoragePersistence } from '@updater/ui-utilities';

export const schema: SuperSchema = {
  $id: 'example-persistence-schema',
  type: 'object',
  properties: {
    email: {
      title: 'email',
      type: 'string',
    },
  },
};

const persistence = LocalstoragePersistence('some-user-id');

export const MyForm = () => {
  const handleSubmit = (values: unknown) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };

  return (
    <>
      <h3>Type into the box below then refresh your browser!</h3>
      <FormProvider
        schema={schema}
        onSubmit={handleSubmit}
        persistence={{
          connector: persistence,
        }}
      >
        <Form />
      </FormProvider>
    </>
  );
};
