import { Validator, Schema, ValidationError } from 'jsonschema';
import set from 'lodash/set';
import { FormikErrors } from 'formik';
import { DefaultErrorMessages, DEFAULT_ERROR_MESSAGES } from '../constants';
import { SuperSchema } from '../types';

/**
 * Retrieves the default error message from the schema
 *
 * @param validationError {ValidationError}
 */
const getDefaultErrorMessage = (validationError: ValidationError): string => {
  const name = validationError?.name as keyof DefaultErrorMessages;

  if (DEFAULT_ERROR_MESSAGES?.[name]) {
    return DEFAULT_ERROR_MESSAGES[name];
  }

  return 'This field is not valid';
};

/**
 * Attempts to retrieve a custom error message from the field schema
 *
 * @param validationError {ValidationError}
 */
const getCustomErrorMessage = (
  validationError: ValidationError
): string | undefined => {
  const schema = validationError.schema as SuperSchema;
  const name = validationError?.name as keyof DefaultErrorMessages;

  // special handling of `required` errors because the path is different
  if (name === 'required') {
    const argument = validationError?.argument ?? '';

    const customMessage = schema?.properties?.[argument]?.ui?.messages?.[name];

    if (customMessage) return customMessage;
  }

  return schema?.ui?.messages?.[name];
};

export type ValidationFunction<Values> = (
  value: unknown
) => FormikErrors<Values>;

export type MakeValidateOptions = {
  schema: SuperSchema;
};

/**
 * Creates a validation function that can be passed into `formikContext.validate`
 *
 * @param options {MakeValidateOptions}
 */
export const makeValidate = <Values>(
  options: MakeValidateOptions
): ValidationFunction<Values> => {
  const validator = new Validator();
  const { schema } = options;
  return (value: unknown): FormikErrors<Values> => {
    return validator
      .validate(value, schema as Schema)
      .errors.reduce((errors, validationError) => {
        const { name } = validationError;

        // Special case for required since the error shows
        // up on the parent "object" node rather than field itself
        const fieldPath =
          name === 'required'
            ? validationError.path.concat([validationError.argument])
            : validationError.path;

        const customErrorMessage = getCustomErrorMessage(validationError);
        const errorMessage =
          customErrorMessage ?? getDefaultErrorMessage(validationError);

        set(errors, fieldPath, errorMessage);

        return errors;
      }, {});
  };
};
