import * as Informant from '@updater/informant-core';
import {
  useInRouterContext,
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { useReactRouterNavigator } from '../../hooks';

const ReactRouterRouterNavigateProvider = (
  props: Informant.NavContextProps
) => {
  const navigator = useReactRouterNavigator();
  return <Informant.NavProvider navigator={navigator} {...props} />;
};

export const ReactRouterNavigator = ({
  children,
  shouldRender = false,
  ...props
}: Informant.NavContextProps) => {
  const { layoutSchema } = Informant.useForm();
  const inRouterContext = useInRouterContext();

  if (!shouldRender) return <></>;

  return inRouterContext ? (
    <Routes>
      <Route
        path="/*"
        element={
          <ReactRouterRouterNavigateProvider {...props}>
            {children}
          </ReactRouterRouterNavigateProvider>
        }
      />
    </Routes>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route
          path={`${layoutSchema?.basePath}/*`}
          element={
            <ReactRouterRouterNavigateProvider {...props}>
              {children}
            </ReactRouterRouterNavigateProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
