// Informant Example
// ***
// This shows how you can override the standard components
// with your own components

import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';

const MyCustomInput = (props: any) => (
  <>
    <input {...props} />
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.error}
  </>
);

export const schema: SuperSchema = {
  type: 'object',
  properties: {
    email: {
      title: 'email',
      type: 'string',
    },
  },
};

const components = {
  text: MyCustomInput,
};

export const MyForm = () => {
  const handleSubmit = (values: unknown) => {
    alert(`Submitted values: ${JSON.stringify(values, null, 2)}`);
  };

  return (
    <>
      <FormProvider
        schema={schema}
        onSubmit={handleSubmit}
        components={components}
      >
        <Form />
      </FormProvider>
    </>
  );
};

export default MyForm;
