import { UISchema } from '../types';

export type DefaultErrorMessages = UISchema['messages'];

/**
 * These are the default error messages
 */
export const DEFAULT_ERROR_MESSAGES: NonNullable<DefaultErrorMessages> = {
  required: 'This field is required',
  pattern: 'This field is not formatted correctly',
  minLength: 'Not enough characters',
  maxLength: 'Too many characters',
};
