import React, { FC } from 'react';
import * as ForeignTypes from './foreign-types';
import * as NaturalTypes from './natural-types';

/**
 * A exhaustive list of all requirable components needed
 * to support a target environment — like react-dom is to react
 *
 * Example:
 *  SomeComponent: React.FC<SomeComponentProps>;
 *
 * TODO: resolve name issue to better serve informant/core going forward
 */
export type SourceComponents = {
  Address: FC<NaturalTypes.AddressProps>;
  BackButton: FC<ForeignTypes.BackButtonProps>;
  Checkbox: FC<ForeignTypes.CheckboxFieldProps>;
  Checklist: FC<ForeignTypes.ChecklistFieldProps>;
  CheckPillList: FC<ForeignTypes.ChecklistFieldProps>;
  DateField: FC<ForeignTypes.TextFieldProps>;
  DatePickerField: FC<ForeignTypes.DatePickerFieldProps>;
  Debugger: FC;
  FieldHeading: FC<NaturalTypes.FieldHeadingProps>;
  FieldObject: FC<NaturalTypes.FieldObjectProps>;
  FieldSet: FC;
  FieldWrapper: FC<NaturalTypes.FieldWrapperProps>;
  Form: FC;
  NextButton: FC;
  PhoneNumberField: FC<ForeignTypes.PhoneNumberFieldProps>;
  RadiolistField: FC<ForeignTypes.RadiolistFieldProps>;
  ScreenContents: FC;
  ScreenHeading: FC;
  SecurityField: FC<ForeignTypes.TextFieldProps>;
  SelectField: FC<ForeignTypes.SelectFieldProps>;
  SubmitButton: FC<ForeignTypes.SubmitButtonProps>;
  SwitchField: FC<ForeignTypes.CheckboxFieldProps>;
  TextField: FC<ForeignTypes.TextFieldProps>;
  ToggleField: FC<ForeignTypes.CheckboxFieldProps>;
  SSNField: FC<ForeignTypes.SSNFieldProps>;
  FileUploader: FC<
    ForeignTypes.FileUploaderProps & { error: string | undefined }
  >;

  /**
   * fallback
   *
   * Essential to the definition of our source components is how we handle
   * absence. The expected output is `null` — but how it is handled is
   * ultimately up to the end user to decide
   *
   * The use cases for a fallback range from testing, platform adoption,
   * to reporting during a version migration.
   */
  fallback?: <T extends keyof SourceComponents>(
    name: T,
    source: SourceComponents
  ) => SourceComponents[T];
};

export type SourceComponentName = Exclude<keyof SourceComponents, 'fallback'>;

export const SourceComponentContext = React.createContext(
  {} as SourceComponents
);
