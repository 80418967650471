import pick from 'lodash/pick';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';
import { SuperSchema } from '../types';

type ScreenProps = {
  schema: SuperSchema;
  fields: string[];
};

/**
 * Returns a partial schema of a SuperSchema based on some properties
 * @param schema An input super schema or json schema
 * @param fields the fields to build the sub-schema from
 */

export const getPartialSchema = ({
  schema,
  fields,
}: ScreenProps): SuperSchema => {
  const schemaSubset = pick(schema.properties, fields) as
    | {
        [key: string]: SuperSchema;
      }
    | undefined;
  const required = intersectionWith(fields, schema.required ?? [], isEqual);

  const partialSchema: SuperSchema = {
    properties: schemaSubset,
    required,
  };

  // TODO you should recursively do this, if the type if object, for nested schemas.
  // This should be done for this story: https://app.shortcut.com/updater/story/74450/create-screens-in-informant

  return partialSchema;
};
