import { FormikErrors } from 'formik';
import { LayoutSchemaScreen } from '../types';

import { isSubschemaValid } from './is-subschema-valid';

type ScreenProps = {
  screens: LayoutSchemaScreen[];
  errors?: FormikErrors<unknown>;
};

/**
 * Gets the latest screen of a LayoutSchema form
 * @param errors the errors of the form
 * @param screen The screens of a LayoutSchema
 */
export const getLatestScreen = ({
  errors,
  screens,
}: ScreenProps): [LayoutSchemaScreen | null, number] => {
  if (!screens || screens?.length === 0)
    throw Error('Unable to find current screen, layoutSchema is empty.');

  let latestScreen: LayoutSchemaScreen | null = null;
  let index = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const screen of screens) {
    const isValid = isSubschemaValid({
      screen,
      errors,
    });
    if (!isValid) {
      latestScreen = screen;
      break;
    }
    index += 1;
    latestScreen = screen;
  }

  return [latestScreen, latestScreen ? index : -1];
};
